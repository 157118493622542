import { devtoolsExchange } from '@urql/devtools';
import { cacheExchange } from '@urql/exchange-graphcache';
import { IntrospectionQuery } from 'graphql';
import { createClient, dedupExchange, fetchExchange } from 'urql';
import { SetlistSongFragmentDoc, UpdateSetlistSongMutationVariables } from './generated/graphql';
import schema from './schema.json';
import { settings } from './settings';

// const storage = makeDefaultStorage({
//   idbName: 'graphcache-v3', // The name of the IndexedDB database
//   maxAge: 7, // The maximum age of the persisted data in days
// });

export const client = createClient({
  url: `${settings.serverURL}${settings.graphqlURL}`,
  fetchOptions: () => ({
    credentials: 'include',
    mode: 'cors',
  }),
  exchanges: [
    devtoolsExchange, 
    dedupExchange, 
    cacheExchange({
      schema: schema as any as IntrospectionQuery,
      // storage,
      keys: {
        // THere is only every one of these, since we are single user system
        // so hardcode an ID.  Not using an int to avoid confusing for user
        Me: data => "me",
        // These types are just grouped properties of their parent types
        // so we are explicitly not giving them a key
        LibraryImage: () => null,
        TransposeInfo: data => null,
        Image: () => null,
      },
      optimistic: {
        updateSetlistSong: (variables, cache, info) => {

          const {input, id} = variables as UpdateSetlistSongMutationVariables

          // Read data from cache
          const setlistsong = cache.readFragment(SetlistSongFragmentDoc, {__typename: "SetlistSong", id}) as any
          
          // Update it with variable data
          if (typeof input.notes === "string") setlistsong.notes = input.notes
          if (typeof input.transpose?.semitones === "number") setlistsong.transpose.semitones = input.transpose.semitones
          if (typeof input.transpose?.style === "string") setlistsong.transpose.style = input.transpose.style
          
          // Return the optimistic update
          return { setlistsong, __typename:"UpdateSetlistSongPayload" }
        },
        // deleteSetlistSong: (variables, cache, info) => {
        //   console.log('humphrey', variables, cache, info)
        //   return null
        //   // return {
        //   //   __typename: "SetlistSong",

        //   // }
        // }
      }
    }), 
    fetchExchange,
  ],
});
