

export type FieldValidator = (value: string) => undefined | string


export const required: FieldValidator = (value: string) => {
  if (value.trim().length === 0) 
    return 'This field is required'
}

export const minLength = (length: number, message?: string) => (value: string) => {
  if (value.length > length) 
    return message || `Must be at least ${length} characters`
}

export const maxLength = (length: number, message?: string) => (value: string) => {
  if (value.length > length) 
    return message || `Please enter less than ${length} characters`
}

export const customMsg = (validator: FieldValidator, customMessage: string) =>
  (value: string) => {
    const e = validator(value)
    if (e) 
      return customMessage
  }