import cs from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { SetlistSongFragment } from '../generated/graphql';
import { LibraryContext } from '../library/LibraryContext';

interface Props {
  songs: ReadonlyArray<SetlistSongFragment>
  setlistId: number
  selectedId: number
  add?: boolean
}


export const SetlistSongs = (props: Props) => {
  const lib = React.useContext(LibraryContext)
  return (
    <div className="list-group list-group-flush border-top border-bottom">

      {/* Details song button */}
      <Link 
        to={lib.buildLink.setlist(props.setlistId)} 
        className={cs('list-group-item list-group-item-action d-flex', !props.selectedId && 'active')}
      >
        <div className="mr-auto">Details</div>
      </Link>

      {/* Songs in the set */}
      {props.songs.map((item, index) => 
        <Link 
          to={lib.buildLink.setlistsong(props.setlistId, item.id)} 
          key={item.id} 
          className={cs('list-group-item list-group-item-action', item.id === props.selectedId && 'active')}
        >
          <div className=" d-flex">
            <div className="pr-2">{index + 1}.</div>
            <div className="mr-auto">{item.song && item.song.title}</div>
            <div className="pl-3">{item.transpose.toKey || (item.transpose.semitones === 0 ? '' : `${item.transpose.semitones}`)}</div>
          </div>
          {/* {item.song.author && <div className="small font-italic">{item.song.author}</div>} */}
          {item.notes && <div className="small font-italicX">{item.notes}</div>}
        </Link>
      )}
      
      {/* Add song button */}
      {props.add &&
        <Link 
          to={lib.buildLink.setlistAddSong(props.setlistId)} 
          className={cs('list-group-item list-group-item-action d-flex font-weight-bold', !props.selectedId && 'active')}
        >
          <div className="mr-auto">+ Add Song</div>
        </Link>
      }
    </div>
  );
};