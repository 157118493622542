import React from 'react';
import { LibrariesFragment, LibraryFragment, MeFragment, useAppQuery } from '../generated/graphql';


export interface AppContextData {
  me: MeFragment | null
  library: LibraryFragment | null,
  libraries: ReadonlyArray<LibrariesFragment> | null
  songExpanded: boolean
  setlistExpanded: boolean
  toggleSongExpanded: (value?: boolean) => void
  toggleSetlistExpanded: (value?: boolean) => void
  // setContext: (newContext: Omit<AppContextData, 'toggleSongExpanded' | 'toggleSetlistExpanded' | 'setContext'>) => void
}

export const AppContext = React.createContext<AppContextData>({
  me: null,
  library: null,
  libraries: null,
  songExpanded: false,
  setlistExpanded: false,
  toggleSongExpanded: v => {},
  toggleSetlistExpanded: v => {},
  // setContext: c => {},
});


export const useAppContextState = (config: {libraryId: number | null}): AppContextData => {
  const [result, executeQuery] = useAppQuery({
    requestPolicy: "cache-and-network", 
    variables: {libraryId: config.libraryId},
  });

  // Manual refresh of query since urql 2.0.0 removed pollInterval
  React.useEffect(() => {
    if (!result.fetching) {
      const id = setTimeout(() => executeQuery({ requestPolicy: 'network-only' }), 60 * 1000);
      return () => clearTimeout(id);
    }
  }, [result.fetching, executeQuery]);

  const [state, setState] = React.useState<Omit<AppContextData, 'me' | 'library' | 'libraries'>>({
    // me: meResult.data?.me || null,
    // libraries: meResult.data?.libraries || null,
    songExpanded: localStorage.getItem('appSongExpanded') === true.toString(),
    setlistExpanded: localStorage.getItem('appSetlistExpanded') === true.toString(),
    toggleSongExpanded: value => {
      const newValue = value === undefined ? !state.songExpanded : value;
      setState({...state, songExpanded: newValue});
      localStorage.setItem('appSongExpanded', newValue.toString());
    },
    toggleSetlistExpanded: value => {
      const newValue = value === undefined ? !state.setlistExpanded : value;
      setState({...state, setlistExpanded: newValue});
      localStorage.setItem('appSetlistExpanded', newValue.toString());
    },
    // setContext: newContext => setState({...state, ...newContext}),
  })
  return {
    ...state,
    me: result.data?.me || null,
    library: result.data?.library || null,
    libraries: result.data?.libraries || null,
  };
}
