import React from 'react';
import { useHistory } from 'react-router';
import { Modal, useModal } from '../chrome/Modal';
import { SetlistForm } from '../setlist/SetlistForm';
import { SetlistsContainer } from '../setlists/SetlistsContainer';
import { LibraryContext } from './LibraryContext';


interface Props {
  pause: boolean
}


export const LibraryTabSetlists = (props: Props) =>  {
  const lib = React.useContext(LibraryContext);
  const modal = useModal();
  console.log('modal', modal.visible)
  const history = useHistory();
  return (
    <div>
      <div className="p-3">
        {!lib.readOnly &&
          <button className="btn btn-light" data-toggle="modal" data-target={`#${modal.htmlId}`}>
            Create new Setlist
          </button>
        }
        <Modal ref={modal.ref} id={modal.htmlId} title="Create new setlist" flush scrollable>
          {modal.visible &&
            <SetlistForm 
              onSaved={newSetlist => {
                modal.hide();
                history.push(lib.buildLink.setlistAddSong(newSetlist.id))
              }}
            />
          }
        </Modal>
      </div>
      {/* <Toolbar burger main={<NavBarTwoLineBtn line1={result.data?.library?.title || 'Library Loading...'} line2="Setlists"/>} onBurger={() => meContext.setSidebarOpen(true)}/> */}
      {/* <h3 className="p-3 mt-navbar">All Setlists</h3> */}
      <SetlistsContainer {...props}/>
    </div>
  )

}

