import { transposeLine } from "./transpose";

export const parseChordChart = (chordChart: string) => {};

export interface Line {
  content: string;
  type: "heading" | "chords" | "repeats" | "lyrics";
  slide: number;
}

export class Verse {
  title: string = "";
  slideNumber: number | null = null;
  items: Line[] = [];

  toString() {
    let lines = [];
    if (this.title) lines.push(`${this.title.toUpperCase()}:`);
    lines = [...lines, ...this.items.map(l => l.content)];
    return lines.join("\r\n");
  }

  width = () => Math.max(...(this.items.map(l => l.content.length) || [0]));

  height = () => this.items.length + (this.title.length > 0 ? 1 : 0);

  slide = () =>
    this.items
      .filter(l => l.type === "lyrics")
      .map(l => l.content)
      .join("\n");
}

const parseVerses = (plaintext: string) => {
  // Keep track of slide number
  let slide = 0;

  const verses: Verse[] = [];
  let v = new Verse();
  const lines = plaintext.replace(/\r\n/, "\n").split("\n");
  for (var x = 0; x < lines.length; x++) {
    let line = lines[x].trim();
    // eslint-disable-next-line
    const heading_match = /^ *[\*\[](.*)[\*\]] *$/g.exec(line);
    // eslint-disable-next-line
    const chords_match = /^[>\.](.*)$/g.exec(line);
    const repeats_match = /^[-](.*)$/g.exec(line);
    const space_match = /^ *$/g.exec(line);
    const colbreak_match = /^-- *$/g.exec(line);

    if (heading_match) {
      const title = heading_match[1].trimRight();
      v.items.push({ content: title, type: "heading", slide: slide });
      if (!v.title) v.title = title;
    } else if (colbreak_match) {
    } else if (chords_match) {
      v.items.push({
        content: chords_match[1].trimRight(),
        type: "chords",
        slide: slide
      });
    } else if (repeats_match) {
      v.items.push({
        content: repeats_match[1].trimRight(),
        type: "repeats",
        slide: slide
      });
    } else if (space_match) {
      if (v.items.length > 0) verses.push(v);
      v = new Verse();
    } else {
      if (line.startsWith(" ")) line = line.substr(1);
      v.items.push({
        content: line.trimRight().replace(/\|/g, " "),
        type: "lyrics",
        slide: slide
      });
    }
  }
  if (v.items.length > 0) verses.push(v);

  // Set numbers
  let i = 0;
  for (v of verses) {
    if (v.items.filter(l => l.type === "lyrics").length > 0) {
      v.slideNumber = 1;
      i = i + 1;
    }
  }

  return verses;
};

export class ChordChartObject {
  plaintext: string;
  verses: Verse[];
  transposeSemitones: number;
  transposeStyle: "#" | "b";

  constructor(plaintext: string) {
    this.plaintext = plaintext;
    this.verses = parseVerses(plaintext);
    this.transposeSemitones = 0;
    this.transposeStyle = "#";
  }

  transpose(semitones: number, style: "#" | "b" = "#") {
    const transposeBy = semitones - this.transposeSemitones;

    //   Formatted
    if (transposeBy !== 0) {
      for (var verse of this.verses) {
        for (var line of verse.items) {
          if (line.type === "chords") {
            line.content = transposeLine(line.content, transposeBy, style);
          }
        }
      }
    }
    this.transposeSemitones = semitones;
    this.transposeStyle = style;
  }

  maxWidth = () => Math.max(...(this.verses.map(v => v.width()) || [0]));

  slides = () =>
    this.verses.filter(v => v.slideNumber !== null).map(v => v.slide());
}

export const transposeChordChart = (
  verses: Verse[],
  semitones: number,
  style: "#" | "b" = "#"
) => {
  if (semitones === 0) return [...verses];

  //   Formatted
  return verses.map(verse => {
    let newVerse = new Verse();
    newVerse.title = verse.title;
    newVerse.slideNumber = verse.slideNumber;
    newVerse.items = verse.items.map(line => ({
      ...line,
      content:
        line.type === "chords"
          ? transposeLine(line.content, semitones, style)
          : line.content
    }));
    return newVerse;
  });
};
