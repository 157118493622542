import { faAngleRight, faBars } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import React from 'react';
import { useMedia } from 'react-media';
import { Link, useHistory } from 'react-router-dom';
import { BooleanParam, NumberParam, QueryParamConfig, StringParam, useQueryParams } from 'use-query-params';
import { AppContext } from '../app/AppContext';
import { MainContentPane } from '../chrome/MainContentPane';
import { MainContentWithSidebar } from '../chrome/MainContentWithSidebar';
import { Modal, useModal } from '../chrome/Modal';
import { TransposeStyle, useChangeSongKeyMutation, useSongQuery } from '../generated/graphql';
import { icons } from '../icons';
import { LibraryContext } from '../library/LibraryContext';
import { Loading } from '../chrome/Loading';
import { GRID_MEDIA_QUERIES } from '../media';
import { settings } from '../settings';
import { SongsSidebar } from '../songs/SongsSidebar';
import { ReactComponent as LeftSidebarIcon } from '../svg/hm-sidebar-left3.svg';
import { truncateChars } from '../utils/text';
import { DisplayKey, KeySelectProps } from '../transpose/KeySelect';
import { KeySelectSimple } from '../transpose/KeySelectSimple';
import { KeySelectUpDown } from '../transpose/KeySelectUpDown';
import { getSonglibTransposeQuery } from '../transpose/transpose';
import { ChordChartAutoSizing } from './ChordChartAutoSizing';
import { DuplicateSongModal } from './DuplicateSongModal';
import { SongMetaTable } from './SongMetaTable';


interface Props {
  id: number
  readOnly?: boolean
}


// Custom handler for QueryParam that only allows FLATS or SHARPS
// Only allow flats or sharps
const TransposeStyleParm: QueryParamConfig<TransposeStyle | null | undefined> = {
  ...StringParam,
  decode: val => {
    if (val === TransposeStyle.Flats || val === TransposeStyle.Sharps) {
      return val
    }
    return null
  }
}


export const SongView = (props: Props) => {

  const lib = React.useContext(LibraryContext)
  const app = React.useContext(AppContext)

  // State
  const [state, setState] = React.useState({
    expanded: app.songExpanded,
  })

  // Hooks
  const [result] = useSongQuery({variables: {id: props.id}, requestPolicy: "cache-and-network"});
  const song = result.data?.song;
  const media = useMedia({queries: GRID_MEDIA_QUERIES})
  const history = useHistory()
  const [, changeSongKey] = useChangeSongKeyMutation();
  const [queryParams, setQueryParams] = useQueryParams({
    semitones: NumberParam,
    style: TransposeStyleParm,
    duplicateModal: BooleanParam,
    // expanded: BooleanParam,
    edit: BooleanParam,
  })

  // Sidebar visibility
  const sidebarVisible = media.lgGTE && !state.expanded;
  const toggleSidebar = () => {
    const newExpanded = !state.expanded
    setState({...state, expanded: newExpanded});
    app.toggleSongExpanded(newExpanded);
  };
  const toggleEdit = () => setQueryParams({...queryParams, edit: !queryParams.edit}, 'replace');

  // Key
  const transpose: DisplayKey = {
    semitones: queryParams.semitones || 0,
    style: queryParams.style || TransposeStyle.Sharps,
  }
  const handleKeyChange = (value: DisplayKey) => {
    setQueryParams({
      ...queryParams, 
      semitones: (value.semitones ? value.semitones : undefined),
      style: value.style,
    }, 'replace')
  }

  const songDetailsModal = useModal()
  const duplicateModal = useModal();

  const getSongContent = () => {

    if (song) {

      const keySelectProps: KeySelectProps = {
        onChange: handleKeyChange,
        semitones: transpose?.semitones || 0,
        style: transpose?.style || TransposeStyle.Sharps,
        originalKey: song.key,
        align: "right",
        // compact={isSm}
        btnClass: "btn btn-plain",
        onSave: async () => {
          await changeSongKey({input: {
            songId: song.id,
            semitones: transpose?.semitones || 0,
            style: transpose?.style || TransposeStyle.Sharps,
            fromKey: song.key,
          }})
          handleKeyChange({semitones: 0, style: transpose?.style || TransposeStyle.Sharps})
        },
      };
    
      const Modals = () => (
        <>
          <Modal ref={songDetailsModal.ref} id={songDetailsModal.htmlId} title="Song details" size="lg" flush scrollable>
            <SongMetaTable song={song} transpose={transpose}/>
          </Modal>
    
          <Modal id={duplicateModal.htmlId} ref={duplicateModal.ref} title="Save a duplicate of this song into" scrollable flush>
            <DuplicateSongModal
              // visible={duplicate || false}
              songId={song.id}
              onDuplicate={r => {
                // setDuplicate(false, 'replace')
                history.push(r.url)
              }}
              hide={() => {
                duplicateModal.hide();
              }}
            />
          </Modal>
        </>
      );

      return (
        <MainContentPane
          scrollContent
          title={
            <h1 className="my-0 d-flex flex-wrap align-items-end">
              <span className="mr-2">{song.title}</span>
              {song.author && <small className="lead text-muted">{truncateChars(song.author, 50)}</small>}
            </h1>
          }
          back={
            <>
              {media.lgGTE ? 
                (!sidebarVisible && <button className="btn btn-plain rounded-0 d-lg-none" title="Back to songs" onClick={toggleSidebar}>
                  <FontAwesomeIcon icon={faBars}/>
                  <FontAwesomeIcon icon={faAngleRight} className="ml-1"/>
                </button>)
              :
                <Link to={lib.buildLink.songs()} className="btn btn-plain rounded-0" title="Back">
                  <FontAwesomeIcon icon={icons.back}/>
                </Link>
              }
            </>
          }
          actions={
            <div className="d-flex">
              {media.mdGTE &&
                <button 
                  onClick={toggleSidebar} 
                  className={cs("btn btn-plain nav-link", !sidebarVisible && 'active')}>
                    <LeftSidebarIcon title="Toggle visibility of the sidebar"/>
                </button>
              }
              {/* {props.toolbarInfo?.right && <div className="mx-2 d-none d-sm-block" style={{borderRight: '1px solid #555'}}/>} */}
              <>{media.mdGTE ? <KeySelectUpDown {...keySelectProps}/> : <KeySelectSimple {...keySelectProps}/>}</>
              {/* <div className="mx-2 d-none d-sm-block" style={{borderRight: '1px solid #555'}}/> */}
            <div className="nav-item dropdown">
              <button className="nav-link btn btn-plain dropdown-toggleXX px-3" id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <FontAwesomeIcon icon={icons.more}/>
              </button>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-iconed" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="#edit" data-toggle="modal" data-target={`#${songDetailsModal.htmlId}`}><FontAwesomeIcon icon={icons.songDetails}/>Song details...</a>
                <a className="dropdown-item" href={settings.serverURL + song.asPdfLink + getSonglibTransposeQuery(transpose)}><FontAwesomeIcon icon={icons.pdf}/>Download song as PDF</a>
                <div className="dropdown-divider"></div>
                {!props.readOnly && <a className="dropdown-item" href="#edit" onClick={e => {e.preventDefault(); toggleEdit()}}><FontAwesomeIcon icon={icons.edit}/>Edit</a>}
                {!props.readOnly && <a className="dropdown-item" href="#edit"><FontAwesomeIcon icon={icons.delete}/>Delete...</a>}
                <div className="dropdown-divider"></div>
                {<a className="dropdown-item" href="#edit" data-toggle="modal" data-target={`#${duplicateModal.htmlId}`}><FontAwesomeIcon icon={icons.duplicateSong}/>Copy or duplicate song...</a>}
              </div>
            </div>
            
            {/* {props.minimise &&
              <li className="nav-item active">
                <Link to={props.minimise} className="nav-link px-3" title="Minimise"><FontAwesomeIcon icon={icons.minimise}/></Link>
              </li>
            } */}
          {/* // </ul> */}
            </div>
          }
        >
          <ChordChartAutoSizing
            plainText={song?.chordChart || ''}
            sizeSource={sidebarVisible}
            transpose={transpose}
          />
          <Modals/>
    
        </MainContentPane>
      )
    }
  }

  const SongContent = getSongContent() || (result.fetching ? <Loading/> : <>Error</>);

  if (media.lgGTE) {
    return (
      <MainContentWithSidebar
        expanded={!sidebarVisible}
        onToggle={toggleSidebar}
        sidebar={<SongsSidebar/>}
      >
        {SongContent}
      </MainContentWithSidebar>
    )
  }
  return SongContent;
};