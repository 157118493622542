import cs from 'classnames';
import React from 'react';


interface Props {
  active: boolean
  children: React.ReactNode
  name: string
}


export const LibraryTab = React.forwardRef<HTMLDivElement, Props>((props, ref) => (
  <div 
    ref={ref}
    className={cs('tab-pane fade h-100', props.active && 'show active')} 
    role="tabpanel" 
    aria-labelledby={`${props.name}-tab`}
    style={{overflow: 'auto'}}
  >
    {props.children}
  </div>
))
