import { faSortAmountDown } from '@fortawesome/pro-light-svg-icons';
import { faSortAlphaDown, faSortAmountUp, faSortNumericDown, faSortNumericUp, faSortSizeDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import React from 'react';
import { SongSort, SongSortField, SortDirection } from '../generated/graphql';
import { useElementId } from '../hooks/elementIds';


interface Props {
  value: SongSort
  search: boolean
  onChange?: (valule: SongSort) => void
}


const SORT_BY_LIST = [
    {text: 'Song Title (A-Z)', icon: faSortAlphaDown, sort: {by: SongSortField.Title, direction: SortDirection.Asc, } },
    {text: 'Search Ranking', icon: faSortAlphaDown, sort: {by: SongSortField.Title, direction: SortDirection.Asc, }, search: true },
    {text: 'Usage - Most used', icon: faSortAmountDown, sort: {by: SongSortField.Usage, direction: SortDirection.Asc, } },
    {text: 'Usage - Least used', icon: faSortAmountUp, sort: {by: SongSortField.Usage, direction: SortDirection.Desc, } },
    {text: 'Created - Most recent', icon: faSortNumericDown, sort: {by: SongSortField.Created, direction: SortDirection.Desc, } },
    {text: 'Created - Oldest', icon: faSortNumericUp, sort: {by: SongSortField.Created, direction: SortDirection.Asc, } },
    {text: 'Modified - Most recent', icon: faSortSizeDown, sort: {by: SongSortField.Modified, direction: SortDirection.Desc, } },
]


const isEq = (s1: SongSort, s2: SongSort) => s1.by === s2.by && s1.direction === s2.direction


export const SongsSortDropdown = (props: Props) => {
  const id = useElementId('songs-sort')
  const current = SORT_BY_LIST.filter(s => isEq(s.sort, props.value))[0]
  

  return (
    <div className="dropdown dropdown-menu-iconed">
      <button title={current.text} className="btn btn-link dropdown-toggle rounded-0" type="button" id={id} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <FontAwesomeIcon icon={current.icon}/>
      </button>
      <div className="dropdown-menu" aria-labelledby={id}>
        {SORT_BY_LIST.map(s => 
          <a key={s.text} 
            href={`#${s.text}`} 
            className={cs(
              `dropdown-item`, 
              s.search && !props.search && 'disabled',
              isEq(s.sort, props.value) && 'active'
            )}
            onClick={e => {
              e.preventDefault();
              props.onChange && props.onChange(s.sort);
            }}
          ><FontAwesomeIcon icon={s.icon}/>{s.text}</a>
        )}
      </div>
    </div>
)}