import cs from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { SongsFragment } from '../generated/graphql';
import { groupByLetter } from './utils';
import { LibraryContext } from '../library/LibraryContext';
import styles from './SongsByLetter.module.scss';
import { Props } from './SongsListGroup';


export interface BaseProps {
  onSelect?: (id: number) => void
  title?: React.ReactNode | null
  selectedId?: number
}


export const SongsByLetter = (props: Props) => {

  const lib = React.useContext(LibraryContext)
  const byLetter = groupByLetter<SongsFragment>(props.songs);

  return (
    <div className={cs(styles.columns, props.className)}>
      {byLetter.map(grp => (
        <div
          key={grp.letter}
          className={cs(styles.letterGroup)}
          id={grp.letter}
        >
          <h4 className="text-pop">{grp.letter.toUpperCase()}</h4>
          {grp.songs.map(song => (
            <div key={song.id}>
              <Link
                to={lib.buildLink.song(song.id) + '?sidebar=0'}
                title={`${ song.title }${song.author  ? ` by ${ song.author }`: ''}`}
              >
                {song.title}
              </Link>
            </div>
          ))}
        </div>
      ))}
    </div>
    // <div className="list-group list-group-flush">
    //   {props.songs.map(song => (
    //     <Link 
    //       key={song.id} to={buildLink.song(props.libraryId, song.id)} 
    //       onClick={(e) => {
    //         if (props.onSelect) {
    //           e.preventDefault()
    //           props.onSelect(song.id)
    //         }
    //       }}
    //       className={cs("list-group-item list-group-item-action", song.id === props.selectedId && 'active')}
    //     >
    //       {song.title}
    //     </Link>
    //   ))}
    // </div>
  );
};
