

export interface ListInfo<T> {
  count: number
  selectedNumber: number | null
  selectedIndex: number | null
  selectedItem: T | null
  prevIndex: number | null
  nextIndex: number | null
  prevItem: T | null
  nextItem: T | null
  items: ReadonlyArray<T>
}


export const getListInfo = <T>(
  items: ReadonlyArray<T>, 
  isSelected: (item: T) => boolean,
): ListInfo<T> => {
  const selectedIndex = items.map((o,i) => isSelected(o) ? i : null).filter(o => o !== null)[0]

  const prevIndex = selectedIndex !== null ? 
      (selectedIndex > 0 ? selectedIndex - 1 : null) 
    : 
      (items.length > 0 ? items.length - 1 : null)

  const nextIndex = selectedIndex !== null ? 
      (selectedIndex < items.length - 1 ? selectedIndex + 1 : null)
    :
      (items.length > 0 ? 0 : null)

  return {
    count: items.length,
    items,
    selectedNumber: selectedIndex !== null ? selectedIndex + 1 : null,
    selectedIndex,
    selectedItem: selectedIndex !== null ? items[selectedIndex] : null,
    prevIndex,
    nextIndex,
    prevItem: prevIndex !== null ? items[prevIndex] : null,
    nextItem: nextIndex !== null ? items[nextIndex] : null,
    // prevId: prevIndex ? items[prevIndex].id : null,
    // nextId: nextIndex ? items[nextIndex].id : null,
    // selectedId: selectedIndex !== null ? items[selectedIndex] : null,
  }
}