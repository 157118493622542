import { Verse } from "../transpose/chordcharts";

export interface Layout {
  columns: Verse[][];
  lineHeight: number;
  fontSize: number;
}

var getMaxLineLength = (verses: Verse[]) =>
  Math.max(...verses.map(v => v.width()), 0);

//  Auto Scale Text
export const calculateLayout = (
  verses: Verse[],
  maxWidth: number,
  maxHeight: number
): Layout => {
  const splitVerses = splitColumns(verses);
  var lineHeight = 0;

  const lineHeight1 = Math.min(
    autoSizeFromHeight(false, splitVerses, maxHeight),
    autoSizeFromWidth(false, splitVerses, maxWidth)
  );
  const lineHeight2 = Math.min(
    autoSizeFromHeight(true, splitVerses, maxHeight),
    autoSizeFromWidth(true, splitVerses, maxWidth)
  );

  let twoCols: boolean = false;
  if (lineHeight1 > lineHeight2) {
    twoCols = false;
    lineHeight = lineHeight1;
  } else {
    twoCols = true;
    lineHeight = lineHeight2;
  }

  lineHeight = Math.min(50, lineHeight);
  lineHeight = Math.max(12, lineHeight);
  const fontSize = (lineHeight / 16) * 110;

  return {
    lineHeight,
    fontSize,
    columns: twoCols ? splitVerses : [verses]
  };
};

function autoSizeFromHeight(
  twoColumns: boolean,
  splitColumns: Verse[][],
  maxHeight: number
) {
  // How many lines are we auto sizing for?
  var numLines = 0;
  if (twoColumns && splitColumns.length === 2) {
    numLines = getMostLinesInColumn(twoColumns, splitColumns);
  } else {
    numLines = splitColumns.reduce(
      (p, c) => p + c.reduce((a, b) => a + b.height(), 0),
      0
    );
  }

  // Which means each line should be this high
  if (numLines > 0) return (maxHeight - numLines / 3) / numLines;
  else return 20; // Don't want any divide by zero errors ;-)
}

function autoSizeFromWidth(
  twoColumns: boolean,
  cols: Verse[][],
  maxWidth: number
) {
  // Get the width of the screen and the number of chars of the longest line
  var w: number = maxWidth,
    chars = cols.reduce(
      (p, c) => Math.max(p, c.reduce((p, c) => Math.max(p, c.width()), 0)),
      0
    );
  if (twoColumns && cols.length === 2) {
    w = (w / 2) * 0.95; // column gutter
  }

  // Therefore, each char should be this wide
  var pixelsPerChar = w / chars;

  // Use multiplier to get the line-height from the width
  return pixelsPerChar * 2.075;
}

const getColHeight = (col: Verse[]) =>
  Math.max(0, col.reduce((p, c) => p + c.height(), 0));

function getMostLinesInColumn(twoColumns: boolean, splitColumns: Verse[][]) {
  if (twoColumns) {
    return Math.max(0, ...splitColumns.map(getColHeight));
  } else {
    return splitColumns.map(getColHeight).reduce((p, c) => p + c, 0);
  }
}

const getTotalLines = (verses: Verse[]) =>
  verses.map(v => v.height()).reduce((p, c) => p + c, 0);

const isBetterAsSingleColumn = (verses: Verse[]) => {
  const chars = getMaxLineLength(verses),
    lines = getTotalLines(verses),
    ratio = chars / lines;
  return ratio >= 1.9;
};

const splitColumns = (verses: Verse[]) => {
  if (isBetterAsSingleColumn(verses)) return [verses];

  //   var numVerses = $(".chordchart .verse").length;
  let bestPosition = 0;
  let bestDiff = getTotalLines(verses);
  for (var i = verses.length; i > 0; i--) {
    const a = verses.slice(0, i).reduce((p, c) => p + c.height(), 0);
    const b = verses
      .slice(i, verses.length)
      .reduce((p, c) => p + c.height(), 0);
    const diff = Math.abs(a - b);
    if (diff < bestDiff) {
      bestDiff = diff;
      bestPosition = i;
    }
  }

  if (bestPosition > 0 && bestPosition < verses.length) {
    return [
      verses.slice(0, bestPosition),
      verses.slice(bestPosition, verses.length)
    ];
  }
  return [verses];
};

export const calculateColumnsAndScale = (
  verses: Verse[]
): { columns: number; scale: number } => {
  return { columns: 1, scale: 1 };
};
