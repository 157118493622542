import React from 'react';
import { useRouteMatch } from "react-router-dom";
import { LibraryView } from '../library/LibraryView';
import { LibrarySelect } from '../LibrarySelect';
import { routes } from '../routes';
import { Unauthenticated } from '../Unauthenticated';
import { AppContext, useAppContextState } from './AppContext';


function App() {
  const libraryMatch = useRouteMatch<{library: string}>(routes.library)
  const libraryId = libraryMatch ? parseInt(libraryMatch.params.library) : null;
  const appContextState = useAppContextState({libraryId});
  
  // Prompt to login in not authenticated
  if (appContextState.me?.isAuthenticated === false) return <Unauthenticated/>;

  // Show the library
  if (libraryId !== null) return (
    <AppContext.Provider value={appContextState}>
      <div className="d-flex h-100 flex-column">
        <div className="flex-fill overflow-auto">
          <LibraryView id={libraryId} library={appContextState.library} me={appContextState.me || undefined}/>
        </div>
      </div>
    </AppContext.Provider>
  )
  
  // Prompt to select a library if none is selected
  return (
    <div className="p-5 text-center">
      {appContextState.me?.user?.shortName && `Welcome ${appContextState.me.user.shortName || ''}`}
      <br/>
      <br/>
      <LibrarySelect options={appContextState.libraries || []}/>
    </div>
  );
}

export default App;
