import React from 'react';
import { TransposeStyle } from '../generated/graphql';
import { DisplayKey } from '../transpose/KeySelect';
import { ChordChartDisplay } from './ChordChart';
import { SizeMeasure } from './SizeMeasure';


interface Props {
  plainText: string;
  transpose?: DisplayKey
  sizeSource?: any
}


export const ChordChartAutoSizing = (props: Props) => (
  <SizeMeasure
    sizeSource={props.sizeSource}
    render={sizeInfo =>
      sizeInfo ? (
        <ChordChartDisplay
          plainText={props.plainText}
          maxWidth={sizeInfo.width * 0.87}
          maxHeight={Math.max(
            20,
            (window.innerHeight - sizeInfo.offsetTop) * 0.9
          )}
          transpose={props.transpose?.semitones || 0}
          style={props.transpose?.style === TransposeStyle.Flats ? 'b' : '#'}
        />
      ) : (
        <div>Resizing...</div>
      )
    }
  />
)
