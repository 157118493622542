import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { InputFormGroup } from '../forms/InputFormGroup';
import { SetlistFragment, useCreateSetlistMutation, useUpdateSetlistMutation } from '../generated/graphql';
import { LibraryContext } from '../library/LibraryContext';
import { LeaderSelectOptions } from './LeaderSelectOptions';


interface Props {
  setlist?: SetlistFragment
  onSaved?: (setlist: SetlistFragment) => void
}


const defaultValues = {
  title: '',
  date: (new Date()).toISOString().substr(0, 10),
  notes: '',
}


export const SetlistForm = ({ setlist, onSaved }: Props) => {
  const lib = React.useContext(LibraryContext);
  const [, updateSetlist] = useUpdateSetlistMutation();
  const [createSetlistResult, createSetlist] = useCreateSetlistMutation();
  const [libraryError, setLibraryError] = React.useState(false)
  return (
    <Formik
      initialValues={{
        title: setlist?.title || '',
        date: (setlist?.date as string) || (new Date()).toISOString().substr(0, 10),
        notes: setlist?.notes || '',
        leader: "",
      }}
      onSubmit={async values => {
        if (!setlist) {
          const r = await createSetlist({
            input: {
              library: lib.id.toString(), 
              draft: true,
              ...values
            }
          });
          const e = (r.data?.createSetlist?.errors?.filter(e=>e?.field === 'library').length || 0) > 0;
          if (libraryError != e) {
            setLibraryError(e)
          }
          
          const newSetlist = r.data?.createSetlist?.setlist
          // const newId = r.data?.createSetlist?.setlist?.id
          if (newSetlist && onSaved) {
            onSaved(newSetlist)
            // history.push(lib.buildLink.setlist(newId))
          }
        }
        // const prom = setlist
        //   ? updateSetlist({input: {id: setlist.id, ...values}}) 
        //   : createSetlist({input: {library: lib.id.toString(), ...values}});
        // const r = await prom;
        // console.log(r)

      }}
      validationSchema={Yup.object({
        title: Yup.string()
          .max(200, "Maximum of 20 characters")
          .required('Required'),
        date: Yup.date()
          .required('Required')
          .typeError('Not a valid date'),
      })}
    >
      <Form className="p-3">
        <InputFormGroup name="title" label="Title"/>
        <div className="row">
          <div className="col-md">
            <InputFormGroup name="date" label="Date"/>
          </div>
          <div className="col-md">
            <InputFormGroup name="leader" label="Leader" as="select">
              <LeaderSelectOptions libraryId={lib.id}/>
            </InputFormGroup>
          </div>
        </div>

        <InputFormGroup name="notes" label="Notes" as="textarea" rows={5}/>

        

        <div className="d-flex align-items-center">
        {libraryError && <div className="text-danger small">You do not have permission to create a setlist in this library.</div>}
          <input type="submit" className="ml-auto btn btn-primary" value="Create"/>
        </div>
      </Form>
    </Formik>
  );
};