import {
  faArrowLeft, faBars,
  faBooks, faBroadcastTower, faCabinetFiling, faChevronDoubleDown, faChevronDoubleLeft, faChevronDoubleRight, faChevronRight,
  faCircle, faCircleNotch, faCog, faCommentAltLines, faCommentEdit,
  faCopy, faDotCircle, faEllipsisV, faExclamationCircle,
  faExpandArrows, faFilePdf, faInfo, faLineColumns, faListMusic, faLock, faMinus, faMusic, faPencil,
  faPlus, faPresentation, faSave, faSearch, faSignOut, faStepBackward, faStepForward, faTimes, faTrash, faUser
} from "@fortawesome/pro-regular-svg-icons";

export const icons = {
  back: faArrowLeft,
  breadcrumbSeparator: faChevronRight,
  burger: faBars,
  // collapseLeft: faArrowToLeft,
  collapseLeft: faChevronDoubleLeft,
  // collapseLeft: faChevronLeft,
  // expandRight: faArrowToRight,
  expandRight: faChevronDoubleRight,
  expandFullScreen: faExpandArrows,
  close: faTimes,
  contactUs: faCommentEdit,
  delete: faTrash,
  displayColumns: faLineColumns,
  displayTable: faBars,
  documentation: faBooks,
  duplicateSong: faCopy,
  edit: faPencil,
  error: faExclamationCircle,
  // help: faInfo,
  keyDown: faMinus,
  keyUp: faPlus,
  library: faCabinetFiling,
  liveSync: faBroadcastTower,
  loadingSpinner: faCircleNotch,
  minimise: faChevronDoubleDown,
  more: faEllipsisV,
  nextSong: faStepForward,
  pdf: faFilePdf,
  plus: faPlus,
  presenter: faPresentation,
  prevSong: faStepBackward,
  privateLibrary: faLock,
  radioChecked: faDotCircle,
  radioUnchecked: faCircle,
  saveKey: faSave,
  search: faSearch,
  setlist: faListMusic,
  settings: faCog,
  signOut: faSignOut,
  song: faMusic,
  songDetails: faInfo,
  songNotes: faCommentAltLines,
  songs: faMusic,
  user: faUser,
}

// export IconDefinition