import React from 'react';
import { Fetchable } from '../chrome/Fetchable';
import { SetlistFragment, useSetlistsQuery } from '../generated/graphql';
import { LibraryContext } from '../library/LibraryContext';
import { BaseProps, Setlists } from './Setlists';

interface Props extends BaseProps {
  pause?: boolean
}
export const SetlistsContainer = (props: Props) => {
  // const p = useRouteParams()
  const lib = React.useContext(LibraryContext)

  const [result] = useSetlistsQuery({
    variables: {libraryId: lib.id},
    requestPolicy: "cache-and-network",
    pause: props.pause,
  });

  return (
    <Fetchable result={result}>
      {data => 
        <Setlists 
          setlists={(data.setlists?.filter(n => n) as SetlistFragment[]) || []} 
          {...props}
        />
      }
    </Fetchable>
  );
};
