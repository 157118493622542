import React from 'react';
import { AppContext } from '../app/AppContext';
import { Loading } from '../chrome/Loading';
import { useDuplicateSongMutation } from '../generated/graphql';
import { LibraryContext } from '../library/LibraryContext';
import { LibraryLi } from '../LibraryLi';


interface Props {
  songId: number
  onDuplicate: (result: {songId: number, libraryId: number, url: string}) => void
  hide: () => void
}

export const DuplicateSongModal = (props: Props) => {
  const app = React.useContext(AppContext)
  const lib = React.useContext(LibraryContext)
  const [mutateResult, mutate] = useDuplicateSongMutation();
  return (mutateResult.fetching ? 
      <Loading>Duplicating song...</Loading> 
    : 
      <div className="list-group list-group-flush">
        {app.libraries?.map(l => l && 
          <LibraryLi 
            key={l.id}
            type="button" 
            library={l} 
            className="list-group-item list-group-item-action"
            selected={l.id === lib.id}
            onClick={async () => {
              
              const r = await mutate({songId: props.songId, toLibraryId: l.id})

              const d = r.data?.duplicateSong

              if (d && d.library && d.song) {
                props.hide();


                props.onDuplicate({
                  songId: d.song.id, 
                  libraryId: d.library.id,
                  url: lib.buildLink.song(d.song.id)
                })
              }

            }}
          />
        )}
      </div>
  )
}
