import cs from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { SetlistsFragment } from '../generated/graphql';
import { LibraryContext } from '../library/LibraryContext';


export interface BaseProps {
  onAddClick?: () => void
  selectedId?: number
  toSong?: boolean
}

interface Props extends BaseProps {
  readonly setlists: readonly SetlistsFragment[]
}


export const Setlists = (props: Props) => {
  const lib = React.useContext(LibraryContext)
  return (
    <>
      <div className=" p-3">
        {props.onAddClick && <button className="btn btn-outline-secondary" onClick={() => props.onAddClick ? props.onAddClick() : undefined }>Add Setlist</button>}
      </div>
      <div>
        {props.setlists.map(setlist => (
          <Link key={setlist.id} to={props.toSong && setlist.currentSong ? lib.buildLink.setlistsong(setlist.id, setlist.currentSong.id) : lib.buildLink.setlist(setlist.id)} 
                className={cs("nav-link px-3 py-1 d-flex", setlist.id === props.selectedId && 'active bg-primary text-white')}>
            <span className="mr-auto">{setlist.title}</span>
            <span className="text-muted small align-self-end">{setlist.date}</span>
          </Link>
          ))
        }
      </div>
    </>
  )
};
