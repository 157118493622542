import cs from 'classnames';
import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { useElementId } from '../hooks/elementIds';

// Modals are rendered in a different #modal-root so that they are not hidden
export const modalRoot = document.getElementById('modal-root') as HTMLDivElement
if (!modalRoot) throw new Error("COuld not find #modal-root to render modal in")

// Simple component (copied from the docs) that renders a component in a different Portal
class ModalContainer extends React.Component<{children: ReactNode}> {
  el: HTMLDivElement

  constructor(props: {children: ReactNode}) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);  
  }
}

interface Props {
  id: string
  children: React.ReactNode
  footer?: React.ReactNode
  title?: React.ReactNode
  size?: 'sm' | 'lg' | 'xl'
  scrollable?: boolean
  flush?: boolean
}

export const Modal = React.forwardRef<HTMLDivElement, Props>((props, ref) => (
  <ModalContainer>
    <div ref={ref} className="modal fade" id={props.id} tabIndex={-1} aria-labelledby={`${props.id}Label`} aria-hidden="true">
      <div className={cs("modal-dialog", props.scrollable && "modal-dialog-scrollable", props.size && `modal-${props.size}`)}>
        <div className="modal-content">
          <div className="modal-header">
            {props.title && <h5 className="modal-title" id={`${props.id}Label`}>{props.title}</h5>}
            {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className={cs("modal-body", props.flush && 'p-0')}>
            {props.children}
          </div>
          {props.footer && <div className="modal-footer">{props.footer}</div>}
        </div>
      </div>
    </div>
  </ModalContainer>
));


declare const $: any;

interface UseModalConfg {
  visible?: boolean
  // props: Props
  onHide?: () => void
}

export const useModal = ({visible, onHide}: UseModalConfg = {}) => {
  
  const htmlId = useElementId('Modal')
  const ref = React.useRef<HTMLDivElement>(null);
  const [visibleState, setVisibleState] = React.useState(visible || false)
  
  const getModal = () => {
    if (ref.current)
      return $(ref.current);
    return null;
  }

  const result = {
    // component: <Modal ref={ref} {...props}/>,
    ref,
    hide: () => {
      getModal()?.modal('hide');
      setVisibleState(false);
    },
    // show: () => getModal()?.show(),
    show: () => {
      // const 
      const m = getModal();
      console.log(m)
      m?.modal('show');
      setVisibleState(true);
    },
    toggle: () => getModal()?.modal('toggle'),
    htmlId,
    visible: visibleState,
  }

  // Auto show the modal based on the url params
  React.useEffect(() => {
    if (visible) 
      getModal()?.modal('show');
    return () => getModal()?.modal('hide')
  }, [visible])

  // Trigger the onHide method if modal hidden 
  React.useEffect(() => {
    const hideHandler = () => {
      if (onHide) onHide();
      setVisibleState(false);
    }
    const showHandler = () => {
      console.log('shown')
      setVisibleState(true);
    }
    getModal()?.on('hidden.bs.modal', hideHandler);
    getModal()?.on('show.bs.modal', showHandler);
    return () => {
      getModal()?.off('hidden.bs.modal', hideHandler);
      getModal()?.off('show.bs.modal', showHandler);
    }
  }, [onHide])

  return result
}
