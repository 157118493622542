import React from 'react';
import cs from 'classnames';

interface Props {
  back?: React.ReactNode
  title?: React.ReactNode
  actions?: React.ReactNode
  children?: React.ReactNode
  scrollContent?: boolean
}

/*

Example Layout could be

_________________________________________________
| Song Name & Author       |     Action buttons |
| _________________________|____________________|
|                                               |
| Chord Chart                                   |
|                                               |
|_______________________________________________|

*/


export const MainContentPane = (props: Props) => {

  // Track whether the content is scrolled down or all to the top
  const [scrolled, setScrolled] = React.useState(false);
  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const newScrolled = e.currentTarget.scrollTop > 0;
    if (newScrolled !== scrolled) {
      setScrolled(newScrolled);
    }
  }

  return (
    <div className={cs("flex-fill d-flex flex-column", props.scrollContent && 'h-100 w-100')}>
      <div 
        id="main-content-header" 
        className={cs("d-flex align-items-center", scrolled && 'bg-light border-bottom')}
        style={{transition: 'border 500ms, background 500ms', borderColor: 'white'}}
      >
        {props.back}
        <div className="flex-fill p-3 mr-3 d-none d-md-block">
          {props.title}
        </div>
        <div className="ml-auto mr-sm-3">
          {props.actions}
        </div>
      </div>
      <div className={cs('flex-fill px-3', props.scrollContent && 'overflow-auto')} onScroll={handleScroll}>
        <div className="mr-3 d-md-none py-3">
          {props.title}
        </div>
        {props.children}
      </div>
    </div>
  )
}