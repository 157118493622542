import React from 'react';
import { useHistory } from 'react-router-dom';
import { useCreateSetlistSongMutation } from '../generated/graphql';
import { LibraryContext } from '../library/LibraryContext';
import { useSongList } from '../songs/hooks';
import { SongsContainer } from '../songs/SongsContainer';
import { SongsListGroup } from '../songs/SongsListGroup';


interface Props {
  // readonly library: LibraryFragment
  setlistId: number
}


export const SetlistAddSong = (props: Props) => {
  
  const [result, executeMutation] = useCreateSetlistSongMutation();
  const songList = useSongList();
  const history = useHistory();
  
  return (
    // <SongsContainer
    //   title="Select a song to add"
    //   // pause={true}
    //   onSelect={async id => {
    //     const r = await executeMutation({input: {setlist: props.setlistId.toString(), song: id.toString()}})
    //     const newPk = r.data?.createSetlistSong?.setlistSong?.id
    //     if (newPk) {
    //       history.push(lib.buildLink.setlistsong(props.setlistId, newPk, id))
    //     }
    //   }} 
    // >
    <SongsListGroup 
      songs={songList.data?.songs || []} 
      filterQuery={songList.filterQuery}
    />
    // </SongsContainer>
  )
};