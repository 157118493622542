import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import React from 'react';
import { icons } from '../icons';

interface Props {
  sidebar: React.ReactNode
  expanded: boolean
  onToggle: () => void
  children: React.ReactNode
  // scrollable: boolean
}


export const MainContentWithSidebar = (props: Props) => (
  <div id="main-content-with-sidebar" className="d-flex" style={{flexGrow: 2}}>

    {/* SIDEBAR */}
    {props.sidebar && 
      <div className={cs("bg-light ", !props.expanded ? 'd-none d-lg-block' : 'd-none')} style={{width: '340px', minWidth: '340px'}}>
        {props.sidebar}
      </div>
    }

    {/* DIVIDER */}
    <div id="sidebar-divider">
        <button 
            className="btn btn-light px-2" 
            style={{}} 
            title="Hide/Show Sidebar" 
            onClick={props.onToggle}
        >
          <FontAwesomeIcon icon={!props.expanded ? icons.collapseLeft : icons.expandRight}/>
        </button>
    </div>

    {/* MAIN CONTENT */}
    {props.children}
    
    
  </div>
)
