import React from "react";

interface SizeInfo {
  width: number;
  height: number;
  offsetTop: number;
}

interface Props {
  render: (info: SizeInfo | null) => React.ReactNode;
  // When this value changes, things will be re-measured
  sizeSource?: any;
}
interface State {
  info: SizeInfo | null;
}

export class SizeMeasure extends React.Component<Props, State> {
  // element: HTMLDivElement | null = null;
  myRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      info: null
    };
    this.myRef = React.createRef<HTMLDivElement>();
    this.handleResize = this.handleResize.bind(this);
  }

  // This will no
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.measure();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps: Props) {
    // Whenever the value of sizeInfo changes, re-measure the screen
    if (this.props.sizeSource !== prevProps.sizeSource) {
      this.measure();
    }
  }

  measure() {
    if (this.myRef && this.myRef.current) {
      const e = this.myRef.current;
      const newState = {
        ...this.state,
        info: {
          width: e.clientWidth,
          height: e.clientHeight,
          offsetTop: e.offsetTop
        }
      }
      // if (
      //   !this.state.info ||
      //   this.state.info?.width !== newState.info.width ||
      //   this.state.info?.height !== newState.info.height ||
      //   this.state.info?.offsetTop !== newState.info.offsetTop
      // ){
      //   this.setState(newState);
      // }
      this.setState(newState);
    }
  }

  handleResize() {
    this.measure();
  }

  render() {
    return <div id="x-size-measure" ref={this.myRef}>{this.props.render(this.state.info)}</div>;
  }
}
