import cs from 'classnames';
import React from 'react';
import { BackInfo, SidebarHeader } from './SidebarHeader';

interface Props {
  back?: BackInfo
  title?: string
  actions?: React.ReactNode
  fixedTop?: React.ReactNode
  children: React.ReactNode
  className?: string
  // section: NavSection
}


export const Sidebar = (props: Props) => {

  // if (context.expanded === props.section) return (
  if (true) return (
    <div className={cs("d-flex flex-column h-100", props.className)}>
      <SidebarHeader title={props.title} back={props.back} actions={props.actions} />
      {props.fixedTop}
      <div style={{overflowY: 'auto'}}>
        {props.children}
      </div>
    </div>
  )

  return null
};
