import { faFileMusic } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { useFloatingSongQuery } from '../generated/graphql';
import { Loading } from '../chrome/Loading';


interface Props {
  id: number
  linkTo: string
}


export const FloatingSong = (props: Props) => {

  const [result] = useFloatingSongQuery({
    variables: {id: props.id},
    requestPolicy: "cache-first",
  });

  const song = result.data?.song

  if (song) {
    return (
      <Link to={props.linkTo} className="btn btn-dark rounded-0 text-left d-flex align-items-center pl-3 py-1 text-white">
        <FontAwesomeIcon icon={faFileMusic} size="2x"/>
        <div className="d-flex flex-column ml-3">
          <div>{song.title}</div>
          <div className="small">Song {song.author && `by ${song.author}`}</div>
        </div>
      </Link>
    )
  }
  
  return <Loading/>
};