import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { icons } from '../icons';


interface Props {
  children: React.ReactNode
  onClose: () => void
}


export const FloatingItem = (props: Props) => (
  <div className="bg-dark text-white d-flex" style={{opacity: 0.85}}>
    <div className="align-self-center" style={{flexGrow: 2}}>
      {props.children}
    </div>
    <button onClick={props.onClose} className="px-3 btn btn-dark ml-auto rounded-0">
      <FontAwesomeIcon icon={icons.close}/>
    </button>
  </div>
)
