import React from 'react';
import logo from './svg/songlib-logo.svg';


interface Props {}


export const Unauthenticated = (props: Props) => {
  return (
    <div className="d-flex h-100 align-items-center">
      <div className="flex-fill text-center">
        <p style={{maxWidth: '150px'}} className="mx-auto">
          <img src={logo} className="img-fluid" alt="Songlib Logo"/>
        </p>
        <p className="lead">You are not logged in</p>
      </div>
    </div>
  );
};