import cs from 'classnames';
import React from "react";
import styles from "./ChordChart.module.scss";
import { calculateLayout, Layout } from "./ChordChartLayout";
import { ChordChartObject, Line, transposeChordChart } from "../transpose/chordcharts";


const HeadingLine = (props: { line: Line }) => (
  <div className={styles.heading}>{props.line.content}</div>
);


const GeneralLine = (props: { line: Line }) => (
  <div className={styles[props.line.type]}>
    {props.line.content || <span>&nbsp;</span>}
  </div>
);


interface Props extends Layout {
  liveSlide?: number;
}


const ChordChartInner = (props: Props) => (
  <div
    className={cs(
      `chordchart`,
      styles.ChordChart,
      props.columns.length === 2 ? styles.twoCol : styles.oneCol
    )}
    data-columns={2}
    style={{
      fontSize: `${props.fontSize}%`,
      lineHeight: props.lineHeight
    }}
  >
    {props.columns.map((col, colIndex) => (
      <div key={colIndex} className={cs(styles.col, styles[`col${colIndex + 1}`])}>
        {col.map((v, index) => (
          <div
            key={index}
            className={`verse  ${styles.verse} ${
              v.slideNumber !== null ? "hasslide" : ""
            } ${props.liveSlide === v.slideNumber ? "live-slide" : ""}`}
          >
            {v.items.map((line, index) =>
              line.type === "heading" ? (
                <HeadingLine line={line} key={index} />
              ) : (
                <GeneralLine line={line} key={index} />
              )
            )}

            <div className={styles.lyrics} data-slide-no="{{ line.slide }}">
              &nbsp;
            </div>
          </div>
        ))}
      </div>
    ))}
    <div style={{ clear: "both" }} />
  </div>
);


export const ChordChartDisplay = (props: {
  plainText: string;
  maxWidth: number;
  maxHeight: number;
  liveSlide?: number;
  transpose: number;
  style: "#" | "b";
}) => {
  const chordChart = new ChordChartObject(props.plainText);

  const transposedVerses = transposeChordChart(
    chordChart.verses,
    props.transpose,
    props.style
  );

  const layout = calculateLayout(
    transposedVerses,
    props.maxWidth,
    props.maxHeight
  );

  return (
    <ChordChartInner
      fontSize={layout.fontSize}
      lineHeight={layout.lineHeight}
      columns={layout.columns}
      liveSlide={props.liveSlide}
    />
  );
};
