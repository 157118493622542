import cs from 'classnames';
import React from 'react';
import { LEFT, RIGHT, SwipeableProps, SwipeDirections, useSwipeable } from 'react-swipeable';
import { useHorizontalSwipeable } from './hooks';

interface Props extends SwipeableProps {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
  prev: React.ReactNode | null
  next: React.ReactNode | null
  onChanged: (e: { dir: typeof LEFT | typeof RIGHT }) => void
  gutter?: number
  overflow?: number
}

// const getXNext = () => {

// }


export const SwipePanes = (props: Props) => {
  const { 
    children, 
    className, 
    style, 
    delta = 20, 
    gutter = 15,
    overflow = 100,
    ...rest 
  } = props;
  console.log(delta);
  const ref2 = React.useRef<HTMLDivElement>()
  // const delta = props.delta || 10
  const borderWidth = `${gutter}px`

  function getTransform(deltaX: string) {
    // console.log(`translate3d(calc(-100% ${deltaX}), 0px, 0px)`)
    return `translate3d(calc(-100% ${deltaX}), 0px, 0px)`
  }
  const duration = 250;
  function enableTransition(el: HTMLDivElement) {
    el.style.transitionDuration = `${duration}ms`
    el.style.transitionProperty = 'transform'
    el.style.transitionTimingFunction = 'ease-out'
    el.style.transitionDelay = '0s';
  }

  const [firstDir, setFirstDir] = React.useState<SwipeDirections | null>(null);

  const horizontal = useHorizontalSwipeable({
    delta: typeof delta === "number" ? delta : (delta.right || delta.left || 0), 
    // dir: firstDir,
    gutter,
    hasNext: !!props.next,
    hasPrev: !!props.prev,
    overflow,
  })

  function animateX(x: string) {
    if (ref2.current) {
      enableTransition(ref2.current)
      ref2.current.style.transform = `translate3d(${x}, 0px, 0px)`
    }
  }
  function setX(x: string) {
    if (ref2.current) {
      if (ref2.current.style.transition != '')
        ref2.current.style.transition = ''
      ref2.current.style.transform = `translate3d(${x}, 0px, 0px)`
    }
  }

  const eventHandlers = useSwipeable({
    ...rest,
    delta,
    onSwiping: e => {
      if (e.first) {
        setFirstDir(e.dir)
      }
      if (ref2.current && (firstDir === LEFT || firstDir === RIGHT)) {
        setX(horizontal.getTouchPosition(e.deltaX, firstDir))
      }
    },
    onSwiped: e => {
      if (ref2.current && (firstDir === LEFT || firstDir === RIGHT)) {
        if (e.dir === LEFT || e.dir === RIGHT) {

          if (horizontal.isSwipe(e, ref2.current)) {

            animateX(horizontal.getAnimateTo(e.dir))
            setTimeout(() => {
              if (ref2.current && (e.dir === LEFT || e.dir === RIGHT)) {
                setX(horizontal.defaultX)
                props.onChanged({ dir: e.dir })
              }
              setFirstDir(null);
            }, duration + 5)

          }
          else {
            animateX(horizontal.defaultX)
          }

        }
        else {
          animateX(horizontal.defaultX)
        }
      }
    },
  });

  const refPassthrough = (el: HTMLDivElement) => {
    // call useSwipeables ref prop with el    
    eventHandlers.ref(el);
    // set the el to a ref you can access yourself
    if (ref2) {
      ref2.current = el
    }
  }



  return (
    // <div>
    //   <button className="btn btn-link p-0 rounded-circle" style={{position: 'fixed', zIndex: 1000, right: '5px', top: '50%', transform: 'translate(0, -50%)'}}>
    //     <FontAwesomeIcon icon={faChevronCircleRight} size="3x"/>
    //   </button>
      <div
        {...eventHandlers}
        ref={refPassthrough}
        style={{ ...style, transform: getTransform(` - ${borderWidth}`) }}
        className={cs(className, 'd-flex')}
      >
        <div className="w-100" style={{ minWidth: '100%', overflowX: 'hidden'}}>
          {props.prev || <div className="bg-light h-100 text-muted d-flex align-items-center text-center"><div style={{ width: `${overflow}px` }} className="ml-auto">Start of<br />setlist</div></div>}
        </div>

        <div style={{borderRight: `${borderWidth} solid #e9ecef` }}></div>
        
        <div className="w-100" style={{ minWidth: '100%', overflowX: 'hidden' }}>
          {children}
        </div>

        <div style={{borderRight: `${borderWidth} solid #e9ecef` }}></div>

        <div className="w-100" style={{ minWidth: '100%', overflowX: 'hidden'}}>
          {props.next || <div className="bg-light h-100 text-muted d-flex align-items-center text-center"><div style={{ width: `${overflow}px` }}>End of<br />setlist</div></div>}
        </div>
      </div>
    // </div>
  );
}
