import cs from 'classnames';
import React from 'react';


interface Props {
  line1: React.ReactNode
  line2: React.ReactNode
  dropDown?: React.ReactNode
  autoHeight?: boolean
}


export const NavBarTwoLineBtn = (props: Props) => {

  return (
    <ul className="navbar-nav navbar-twoline">
      <li className={cs("nav-item", props.dropDown && "dropdown")}>
        <button className="btn btn-link nav-link d-block text-left py-1 px-0" style={{lineHeight: 1.1}}id="setlistsongDetailsDropdown" data-toggle={props.dropDown && "dropdown"} aria-haspopup={props.dropDown ? "true" : 'false'} aria-expanded="false">
          <div>
            <div style={{whiteSpace: props.autoHeight ? undefined : 'nowrap', overflowX: 'hidden'}}>{props.line1}</div>
            <div style={{fontSize: '0.7em', height: '13px', overflow: 'hidden'}}>{props.line2}</div>
          </div>
        </button>
        {props.dropDown && 
          <div className="dropdown-menu dropdown-menu-iconed" aria-labelledby="setlistsongDetailsDropdown">
            {props.dropDown}
          </div>
        }
      </li>
    </ul>
  );
};