import React from 'react';
import styles from './LibraryModal.module.scss';
import cs from 'classnames';


interface Props {
  children?: React.ReactNode
}


export const LibraryModal = React.forwardRef<HTMLDivElement, Props>((props, ref) => (
  <div ref={ref} className={cs(styles.LibraryModal, props.children && styles.active)}>
    {props.children}
  </div>
))
