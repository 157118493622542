import React from 'react';
import { Sidebar } from '../chrome/Sidebar';
import { SongSortField, SortDirection } from '../generated/graphql';
import { LibraryContext } from '../library/LibraryContext';
import { SongsContainer } from './SongsContainer';
import { SongsListGroup } from './SongsListGroup';
import { SongsSortDropdown } from './SongsSortDropdown';


interface Props {
  selectedSongId?: number
}


export const SongsSidebar = (props: Props) => {
  const lib = React.useContext(LibraryContext)
  return (
    <SongsContainer selectedSongId={props.selectedSongId}>
      {p => 
        <>
          <Sidebar
            title="Songs" 
            back={{to: lib.buildLink.songs()}}
            actions={
              <SongsSortDropdown 
                value={ p.filter ? {by: p.filter.sortBy || SongSortField.Title, direction: p.filter.sortDir || SortDirection.Asc} : {by: SongSortField.Title, direction: SortDirection.Asc}} 
                search={!!p.filter?.q}
                onChange={v => {
                  p.onFilter && p.onFilter({q: '', ...p.filter, sortBy: v.by, sortDir: v.direction})
                }}
              />
            }
            fixedTop={p.filter && p.onFilter && 
              <div className="pb-3 pl-3 pr-3 border-bottom">
                <input 
                  defaultValue={p.filter?.q || ''} 
                  onChange={e => {p.onFilterDebounced && p.onFilterDebounced({...p.filter, q: e.target.value || ''})}}
                  className="form-control form-search-input rounded-pill pl-5"
                  placeholder="Search songs…"
                />
              </div>
            }
          >
            <SongsListGroup {...p} sidebar/>
          </Sidebar>
        </>
      }
    </SongsContainer>
  )
}