import React from 'react';
import { buildLinkForLibrary } from '../routes';


export interface LibraryContextData {
  id: number
  buildLink: ReturnType<typeof buildLinkForLibrary>
  readOnly: boolean
}

export const LibraryContext = React.createContext<LibraryContextData>({
  id: 0,
  buildLink: buildLinkForLibrary(0),
  readOnly: true,
});
