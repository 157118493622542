import React from 'react';
import { FieldValidator } from './validators';

export interface FieldCommitEvent<T extends string> {
  name: T;
  value: string;
}
export interface FieldChangeEvent<T extends string> {
  name: T;
  value: string;
}

export type ValidationStatus = 'is-validating' | 'is-valid' | 'is-invalid' | ''


export interface FieldConfig<T extends string> {
  name: T
  initialValue?: string
  startValidated?: false
  validators?: FieldValidator[]
  partialValidators?: FieldValidator[]
  cleaner?: (value: string) => string
  formatter?: (value: string) => string
  onChange?: (e: FieldChangeEvent<T>) => void
  onCommit?: (e: FieldCommitEvent<T>) => (Promise<null | string> | void)
  error?: string
}


interface State {
  value: string
  isValid: boolean
  isPartialValid: boolean
  error: string | null
  validated: boolean
  validating: boolean
  commitError: string | null
}


export const useField = <T extends string>(config: FieldConfig<T>) => {

  // TODO: Extract all validation & state logic into a hook so that it can be reused for different DOM structures

  const clean = (value: string) => config.cleaner ? config.cleaner(value) : value

  const validate = (value: string, validators?: FieldValidator[]): string | undefined => {
    const cleanedValue = clean(value)
    if (validators) {
      for (let validator of validators) {
        let r = validator(cleanedValue)
        if (r) {
          return r
        }
      }
    }
  }

  const updateValue = (value: string, prevState?: State): State => {
    const error = validate(value, config.validators)
    return {
      // Defaults
      validated: config.startValidated ? (config.initialValue || '').length > 0 : false,
      validating: false,
      commitError: null,
      // Prev State
      ...prevState,
      // Update with new values
      value: config.formatter ? config.formatter(value) : value,
      isValid: error === undefined,
      isPartialValid: validate(value, config.partialValidators) === undefined,
      error: config.error || error || prevState?.commitError || null,
    }
  }
  const [state, setState] = React.useState<State>(updateValue(config.initialValue || ''))

  // If the initial value changes, reset the value in state
  // For example, this allows the same Input to be used for multiple songs
  React.useEffect(() => {
    setState(updateValue(config.initialValue || ''))
  }, [config.initialValue, config.name]);
  

  // DISABLED EXPERIMENT TO UPDATE ALL THE FIELDS BASED ON THE RELAY RESULT CHANGING FIELDS
  // IT ALMOST WORKS BUT IS BUGGY

  // React.useEffect(() => {
  //   // console.log(props.initialValue, state.value)
  //   // const cleanedValue = clean(state.value)
  //   // if (props.initialValue !== cleanedValue)  {
  //     setState({
  //       ...state, 
  //       value: props.formatter ? props.formatter(props.initialValue) : props.initialValue,
  //       isValid: true,
  //       isPartialValid: true,
  //       error: null,
  //     })
  //   // }
  // }, [props.initialValue])

  const handleChange = (event: {target: {value: string}}) => {
    
    const newState = {
      ...updateValue(event.target.value, state),
      validated: false,
    }
    setState(newState)
    if (config.onChange) {
      config.onChange({
        name: config.name,
        value: newState.value,
      })
    }
  }

  const handleBlur = async () => {
    if (state.isValid && !state.validated) {
      
      // Allow the field to be validated or saved to the server
      // If the commit returns a string, it's an error message,
      // so show it
      if (config.onCommit) {
        setState({ ...state, validated: false, validating: true})
        const commitError = await config.onCommit({
          name: config.name, 
          value: clean(state.value),
        })
        
        if (commitError === null || commitError === undefined) {
          setState({ ...state, validated: true, validating: false, commitError: null, isValid: true, error: null})
        }
        else {
          setState({ ...state, validated: true, validating: false, commitError, isValid: false, error: commitError})
        }
      }
      else {
        setState({ ...state, validated: true, validating: false})
      }
    }
    else {
      setState({ ...state, validated: true, validating: false})
    }
  }

  const hasError = !!(config.error || (state.error && (state.validated || !state.isPartialValid)))

  return {
    handleChange,
    handleBlur,
    value: state.value,
    // hasError,
    error: hasError ? config.error || state.error || '' : null,
    validationStatus: 
      (state.validating ? 
        'is-validating' 
      : 
        (state.validated ? 
          (state.isValid ? 'is-valid' : 'is-invalid') 
        : 
          (!state.isPartialValid && 'is-invalid') || ''
        )
      ) as ValidationStatus,

  }
}