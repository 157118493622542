import { match, useRouteMatch } from "react-router-dom";

interface Params {
  library?: string
  setlist?: string
  editsong?: string
  song?: string
  setlistsong?: string
  view?: string
}

export const routes = {
  library:        "/library/:library(\\d+)/",
  setlistSong:    "/library/:library(\\d+)/:view(setlists)/:setlist(\\d+)/:setlistsong(\\d+)/",
  setlistAddSong: "/library/:library(\\d+)/:view(setlists)/:setlist(\\d+)/add-song/",
  setlist:        "/library/:library(\\d+)/:view(setlists)/:setlist(\\d+)/",
  setlists:       "/library/:library(\\d+)/:view(setlists)/",
  editSong:       "/library/:library(\\d+)/:view(songs)/:song(\\d+)/edit/",
  song:           "/library/:library(\\d+)/:view(songs)/:song(\\d+)/",
  songs:          "/library/:library(\\d+)/:view(songs)/",
}


function extractInt(reg_exp: string, path: string, group: number) {
  const m = (new RegExp(reg_exp)).exec(path)
  if (m) return parseInt(m[group])
  return null
}

export const isRoute = {
  song: (path: string) => extractInt('^/song/(\\d+)/(\\d+)/', path, 2),
  setlist: (path: string) => extractInt('^/setlist/(\\d+)/(\\d+)/', path, 2),
  setlistsong: (path: string) => extractInt('^/setlistsong/(\\d+)/(\\d+)/(\\d+)/', path, 3),
}

const rmGrp = (text: string) => text.replace(/\(\\d\+\)/g, '').replace(/:view\((\w+)\)/g, '$1')


export const buildLink = {
  library: (library: number) => rmGrp(routes.library)
    .replace(/:library/g, library.toString()),
  songs: (library: number) => rmGrp(routes.songs)
    .replace(/:library/g, library.toString()),
  song: (library: number, song: number) => rmGrp(routes.song)
    .replace(/:library/g, library.toString())
    .replace(/:song/g, song.toString()),
  editsong: (library: number, song: number) => rmGrp(routes.editSong)
    .replace(/:library/g, library.toString())
    .replace(/:song/g, song.toString()),
  // editSong: (library: number, song: number) => rmGrp(routes.song)
  //   .replace(/:library/g, library.toString())
  //   .replace(/:song/g, song.toString()),
  setlists: (library: number) => rmGrp(routes.setlists)
    .replace(/:library/g, library.toString()),
  setlist: (library: number, setlist: number) => rmGrp(routes.setlist)
    .replace(/:library/g, library.toString())
    .replace(/:setlist/g, setlist.toString()),
  setlistAddSong: (library: number, setlist: number) => rmGrp(routes.setlistAddSong)
    .replace(/:library/g, library.toString())
    .replace(/:setlist/g, setlist.toString()),
  setlistsong: (library: number, setlist: number, setlistsong: number) => rmGrp(routes.setlistSong)
    .replace(/:library/g, library.toString())
    .replace(/:setlistsong/g, setlistsong.toString())
    .replace(/:setlist/g, setlist.toString()),
}

export const buildLinkForLibrary = (libraryId: number) => ({
  library: () => buildLink.library(libraryId),
  songs: () => buildLink.songs(libraryId),
  song: (song: number) => buildLink.song(libraryId, song),
  editsong: (song: number) => buildLink.editsong(libraryId, song),
  setlists: () => buildLink.setlists(libraryId),
  setlist: (setlist: number) => buildLink.setlist(libraryId, setlist),
  setlistsong: (setlist: number, setlistsong: number) => buildLink.setlistsong(libraryId, setlist, setlistsong),
  setlistAddSong: (setlist: number) => buildLink.setlistAddSong(libraryId, setlist),
})

const tryParseInt = (value?: string) => value ? parseInt(value) : null

const tryMatch = (m: match<Params> | null) => {
  if (m) {
    return {
      view: (m?.params.view || null) as ViewName | null,
      library: tryParseInt(m?.params.library),
      song: tryParseInt(m?.params.song),
      setlist: tryParseInt(m?.params.setlist),
      setlistsong: tryParseInt(m?.params.setlistsong),
    }
  }
}

type ViewName =   'setlistsong' | 'songs' | 'setlist' | 'song' | 'library' | 'setlists' | 'settings' | 'editsong'


export const useRouteParams = () => {
  
  const m0 = useRouteMatch<Params>(routes.setlistSong)
  const m1 = useRouteMatch<Params>(routes.songs)
  const m2b = useRouteMatch<Params>(routes.setlists)
  const m2 = useRouteMatch<Params>(routes.setlist)
  const m3 = useRouteMatch<Params>(routes.song)
  const m4 = useRouteMatch<Params>(routes.library)
  
  return tryMatch(m0) || tryMatch(m1) || tryMatch(m2) || tryMatch(m2b) || tryMatch(m3) || tryMatch(m4) || {view: null, library: null, song: null, setlist: null, setlistsong: null}
}