import React from 'react';
import ReactDOM from 'react-dom';
import { AppWrapper } from './app/AppWrapper';
import './index.scss';
import * as serviceWorker from './serviceWorker';
// import 'bootstrap/dist/css/bootstrap.css';


ReactDOM.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>,
  document.getElementById('app-root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
