import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useMedia } from 'react-media';
import { Link, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { LEFT, RIGHT } from 'react-swipeable';
import { BooleanParam, NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { AppContext } from '../app/AppContext';
import { MainContentWithSidebar } from '../chrome/MainContentWithSidebar';
import { useDeleteSetlistMutation, useSetlistQuery } from '../generated/graphql';
import { icons } from '../icons';
import { LibraryContext } from '../library/LibraryContext';
import { GRID_MEDIA_QUERIES } from '../media';
import { routes } from '../routes';
import { DisplayKey } from '../transpose/KeySelectHooks';
import { getListInfo } from '../utils/listInfo';
import { SetlistForm } from './SetlistForm';
import { SetlistMeta } from './SetlistMeta';
import { SetlistSongPane } from './SetlistSongPane';
import { SetlistSongs } from './SetlistSongs';
import { SwipePanes } from '../swiping/SwipePanes';
import { SetlistAddSong } from './SetlistAddSong';


/*
Layout for setlist song is

______________________________________________________________________
| <- Setlist name    | Song Name & Author       |     Action buttons |
|                    | _________________________|____________________|
|    About           | 
|                    | Chord Chart
| 1. First Song   D  |
| 2. Second Song  E  |
| 3. Third Song   A  |
| 4. Fourth Song  G  |
|                    |
| +  Add Song        |
|____________________|_______________________________________________   
*/

interface Props {
  id: number
  className?: string
  onDelete?: () => void
  backTo?: string
}


export const SetlistView = (props: Props) => {
  const app = React.useContext(AppContext);
  const lib = React.useContext(LibraryContext);
  // const u = useRouteParams()
  const u = useRouteMatch<{setlistsong: string}>({path: routes.setlistSong})
  const history = useHistory()

  const [result] = useSetlistQuery({variables: {id: props.id}});
  const listInfo = getListInfo(result.data?.setlist?.songs || [], s => s.id.toString() === u?.params.setlistsong)
  const setlist = result.data?.setlist;
  
  // Using query for state so that reloads, etc work great
  const [queryParams, setQueryParams] = useQueryParams({
    add: BooleanParam,
    edit: BooleanParam,
    // song: NumberParam,
    semitones: NumberParam,
    style: StringParam,
  });
  
  // const toggleEdit = (value?: boolean) => setQueryParams({...queryParams, edit: value !== undefined ? value : !queryParams.edit})
  // const toggleAdd = (value?: boolean) => setQueryParams({...queryParams, add: value !== undefined ? value : !queryParams.add})
  
  // Expanded / Sidebar visibility
  const [expanded, setExpanded] = React.useState(app.setlistExpanded)
  const toggleSidebar = () => {
    app.toggleSetlistExpanded(!expanded);
    setExpanded(!expanded);
  };

  // Delete setlist
  const [, deleteSetlist] = useDeleteSetlistMutation();
  const handleDelete = async () => {
    if (result.data?.setlist?.library.id){
      await deleteSetlist({id: props.id});
      history.push(lib.buildLink.library())
      if (props.onDelete) {
        props.onDelete()
      }
    }
  }

  const media = useMedia({queries: GRID_MEDIA_QUERIES})
  const sidebarVisible = media.lgGTE && !expanded;

  const SetlistPane = ({collapseBtn}: {collapseBtn?: boolean}) => (
    <>
      <div className="d-flex align-items-center">
        <Link to={lib.buildLink.setlists()} className="btn btn-link rounded-0" title="Back to setlists">
          <FontAwesomeIcon icon={icons.back}/>
        </Link>
        <h1 className="my-0 py-3 flex-fill">{setlist?.title || 'Setlist'}</h1>
        {/* <button className="btn btn-link rounded-0 px-3" title="Menu">
          <FontAwesomeIcon icon={icons.more}/>
        </button> */}

        {/* {collapseBtn && 
          <button className="btn btn-link rounded-0" title="Back to setlists" onClick={toggleSidebar}>
            <FontAwesomeIcon icon={faAngleLeft} className="mr-1"/>
            <FontAwesomeIcon icon={faBars}/>
          </button>
        } */}
      </div>

      {setlist && 
        <>
          {queryParams.edit ? 
            <SetlistForm setlist={setlist}/>
          :  
            <SetlistMeta setlist={setlist}/>
          }
          <SetlistSongs songs={setlist.songs} add={!lib.readOnly} setlistId={props.id} selectedId={parseInt(u?.params.setlistsong || '0')} />
        </>
      }

    </>
  );

  const setlistSongPageProps = {
    setlistId: props.id,
    onKeyChange: (t: DisplayKey) => setQueryParams({...queryParams, semitones: t.semitones, style: t.style}),
    sizeSource: sidebarVisible,
    onSidebarToggle: toggleSidebar,
    sidebarToggle: false,
    back: !media.lgGTE,
  }

  const SwipableSongPane = () => (
    <div className="h-100" style={{overflowX: 'hidden'}}>
      <SwipePanes 
        className="swipable-songs h-100"
        preventDefaultTouchmoveEvent={true}
        // trackMouse={true}
        prev={
          listInfo.prevItem ? 
            <SetlistSongPane 
              setlistSong={listInfo.prevItem} 
              listInfo={getListInfo(result.data?.setlist?.songs || [], s => s.id === listInfo.prevItem?.id)} 
              {...setlistSongPageProps}
            /> 
          : 
            null
        }
        next={
          listInfo.nextItem ? 
            <SetlistSongPane 
              setlistSong={listInfo.nextItem} 
              listInfo={getListInfo(result.data?.setlist?.songs || [], s => s.id === listInfo.nextItem?.id)} 
              {...setlistSongPageProps}
            /> 
          : 
            null
        }
        onChanged={e => {
          const newItem = (e.dir === LEFT && listInfo.nextItem) || (e.dir === RIGHT && listInfo.prevItem) || null
          if (newItem) {
            history.replace(lib.buildLink.setlistsong(props.id, newItem.id))
          }
        }}
      >
        {listInfo.selectedItem ? 
          <SetlistSongPane
            {...setlistSongPageProps}
            setlistId={props.id}
            listInfo={listInfo}
            setlistSong={listInfo.selectedItem}
          /> 
          : <div>Loading</div>} 
      </SwipePanes>
    </div>
  )

  const Content = () => (
    <Switch>
      <Route path={routes.setlistSong} component={SwipableSongPane}/>
      <Route path={routes.setlistAddSong}>
        <div className="flex-fill text-center">
          <br/><br/>
          <SetlistAddSong setlistId={props.id}/>
        </div>
      </Route>
      <Route>
        <div className="flex-fill text-center">
          <br/><br/>
          Setlist overview
        </div>
      </Route>
    </Switch>
  )

  if (media.lgGTE) {
    return (
      <MainContentWithSidebar
        expanded={!sidebarVisible}
        onToggle={toggleSidebar}
        sidebar={<SetlistPane collapseBtn/>}
      >
        <Content/> 
      </MainContentWithSidebar>
    )
  }

  if (listInfo.selectedItem) {
    return (
      <Content/>
    )
  }

  return <SetlistPane/>;
}