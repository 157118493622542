import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { icons } from '../icons';


export interface BackInfo {
  to: string,
  text?: string,
}

export interface ToolbarInfo {
  back?: BackInfo
  main?: React.ReactNode
  right?: React.ReactNode
  more?: React.ReactNode
  burger?: boolean
  onBurger?: () => void
  fixed?: 'top' | 'bottom'
  className?: string
  minimise?: string
  autoHeight?: boolean
}

interface Props extends ToolbarInfo {
  clear?: boolean
}


export const Toolbar = (props: Props) => {

  return (
    <nav 
      className={
        cs(
          props.className || "navbar navbar-expand pr-0", 
          !(props.back || props.burger) ? 'pl-3' : 'pl-0', 
          props.fixed && `fixed-${props.fixed}`,
          props.clear ? 'navbar-clear navbar-light': 'navbar-dark text-white bg-dark',
        )
      } 
      style={{height: props.autoHeight ? undefined : '44px', flexShrink: 0}}
    >
      {(props.back || props.burger) &&
        <ul className="navbar-nav">
          {props.back &&
            <li className="nav-item active">
              <Link to={props.back.to} className="nav-link pl-3 pr-3"><FontAwesomeIcon icon={icons.back}/>{props.back.text && <>&nbsp;</>}{props.back.text}</Link>
            </li>
          }
          {props.burger && 
            <li className="nav-item active">
              <button className="btn btn-link nav-link px-3 py-1" onClick={(e => {e.preventDefault();props.onBurger && props.onBurger()})}><FontAwesomeIcon icon={icons.burger}/></button>
            </li>
          }
        </ul>
      }
      {props.main}
      <ul className="navbar-nav ml-auto">
        {props.right}
        {props.more &&
          <li className="nav-item dropdown">
            <button className="nav-link btn btn-link dropdown-toggleXX px-3" id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <FontAwesomeIcon icon={icons.more}/>
            </button>
            <div className="dropdown-menu dropdown-menu-right dropdown-menu-iconed" aria-labelledby="navbarDropdown">
              {props.more}
            </div>
          </li>
        }
        {props.minimise &&
          <li className="nav-item active">
            <Link to={props.minimise} className="nav-link px-3" title="Minimise"><FontAwesomeIcon icon={icons.minimise}/></Link>
          </li>
        }
      </ul>
    </nav>
  );
};