import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';


interface Props {
  tabs: {
    text: string,
    to: string,
    active: boolean,
    icon: IconProp,
    ref: React.RefObject<HTMLDivElement>,
  }[],
  // onScrollTop: (tabIndex: number) => void
}


export const LibraryTabs = (props: Props) => {
  return (
    <ul className="nav nav-pills nav-fill bg-light border-top">
      {props.tabs.map((tab, tabIndex) => (
        <li key={tab.text} className="nav-item" style={{lineHeight: '1.1'}}>
          <Link 
            to={tab.to} 
            id={`${tab.text.toLocaleLowerCase()}-tab`} 
            className={cs(
              "nav-link btn btn-light rounded-0 pb-1 pt-2", 
              tab.active && 'active', 
              (tab.to === '#') && 'disabled'
            )}
            // Scroll content to top if tab is clicked while the tab is open
            onClick={e => {
              if (tab.active) {
                e.preventDefault();
                if (tab.ref.current?.scrollTo){
                  tab.ref.current?.scrollTo({top: 0, behavior: 'smooth'})
                }
              }
            }}
          >
            <FontAwesomeIcon icon={tab.icon}/>
            <div className="small"><small>{tab.text}</small></div>
          </Link>
        </li>

      ))}
    </ul>
  );
  
};