import { LEFT, RIGHT, SwipeEventData } from 'react-swipeable';


interface Config {
  gutter: number
  overflow: number
  delta: number
  // dir: null | SwipeDirections
  hasNext: boolean
  hasPrev: boolean
}


export const useHorizontalSwipeable = (config: Config) => {
  const { gutter, overflow, delta, hasNext, hasPrev } = config;

  const defaultX = `calc(-100% - ${gutter}px)`
  const nextSlideX = `calc(-100% - 100% - ${gutter}px - ${gutter}px)`
  const prevSlideX = `calc(-100% + 100%)`

  function calcSwipingLeft (deltaX: number) {
    if (hasNext)
      return deltaX;
    return  Math.max(deltaX, 0 - overflow - 2 * gutter)
  }

  function calcSwipingRight (deltaX: number) {
    const newX = deltaX - gutter - delta
    if (hasPrev) 
      return newX
    return Math.min(newX, overflow)
  }

  function calcSwipingX (deltaX: number, dir: typeof LEFT | typeof RIGHT) {
      if (dir === LEFT) return calcSwipingLeft(deltaX)
      if (dir === RIGHT) return calcSwipingRight(deltaX)
      return deltaX;
  }

  function getTouchPosition (deltaX: number, dir: typeof LEFT | typeof RIGHT) {
    const newX = calcSwipingX(deltaX, dir)
    return `calc(-100% ${newX <= 0 ? '-' : '+'} ${Math.abs(newX)}px)`;
  }

  function getAnimateXVal (dir: typeof LEFT | typeof RIGHT) {
    if (dir === LEFT && hasNext) return nextSlideX;
    if (dir === RIGHT && hasPrev) return prevSlideX;
    return defaultX
  }
  function getAnimateTo (dir: typeof LEFT | typeof RIGHT) {
    return `${getAnimateXVal(dir)}`
  }

  function isSwipe(e: SwipeEventData, div: HTMLDivElement) {
    if (e.deltaX < 0 && e.dir !== LEFT) return false
    if (e.deltaX > 0 && e.dir !== RIGHT) return false

    // console.log(e.vxvy[0], e.velocity)

    // console.log(e.deltaX, e.dir)
    if (Math.abs(e.deltaX) / div.clientWidth >= 0.1) return true
    // if (e.dir === RIGHT) {
    //   console.
    // }
    return false
  }

  return {
    defaultX,
    nextSlideX,
    prevSlideX,
    getTouchPosition,
    getAnimateTo,
    isSwipe
  };
}
