import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import React from "react";
import { Link } from "react-router-dom";
import { MeFragment, useLogoutMutation } from '../generated/graphql';
import { icons } from '../icons';
import { LibraryContext } from '../library/LibraryContext';
import { buildLink } from "../routes";
import { settings } from '../settings';
import { AppContext } from './AppContext';


interface Props {
  me?: MeFragment;
}



export const AppDrawer = (props: Props) => {
  const lib = React.useContext(LibraryContext)
  const app = React.useContext(AppContext);
  const navHeight = 40;
  const [logoutResult, executeLogoutMutation] = useLogoutMutation();

  return (
    <div className="">

      <nav className="navbar navbar-expand navbar-light text-white bg-light fixed-top" style={{height: `${navHeight}px`}}>
        <span className="navbar-brand mb-0 h1">Songlib</span>
      </nav>
      <div className="bg-light border-bottom pt-5 px-3 pb-2 d-flex align-items-center">
        <div>
          <FontAwesomeIcon icon={icons.user}/> {props.me?.user?.shortName}
        </div>
        <button className="ml-auto btn btn-light" onClick={() => executeLogoutMutation()} disabled={logoutResult.fetching}>
          <FontAwesomeIcon icon={icons.signOut}/>
          Logout
        </button>
      </div>
      <div className="list-group list-group-flush" style={{marginTop: `${navHeight}px`}}>
        {app.libraries?.map(l => 
          <Link 
            key={l.id} 
            to={buildLink.library(l.id)} 
            className={cs("list-group-item", l.id === lib.id && 'active')} 
            // onClick={() => meContext.setSidebarOpen(false)}
          >
            {l.title}
          </Link>
        )}
      </div>
      <hr/>

      {props.me?.isAuthenticated &&
      <div className="list-group list-group-flush">
        <button className="list-group-item text-muted">My account settings</button>
        {props.me.user?.isStaff && <>
          <div className="dropdown-divider"></div>
          <h6 className="dropdown-header">Advanced</h6>
          <button className="list-group-item text-muted">My API Keys</button>
          <a className="list-group-item" href={settings.serverURL + '/admin/'} rel="noreferrer" target="_blank">Django admin</a>
        </>}
        <div className="dropdown-divider"></div>
        <h6 className="dropdown-header">Help &amp; support</h6>
        <button className="list-group-item text-muted">What's new?</button>
        <a className="list-group-item" rel="noreferrer" href="https://songlib.uservoice.com/" target="_blank">Give feedback / Contact support...</a>
        <a className="list-group-item" rel="noreferrer" href="http://songlib.uservoice.com/knowledgebase" target="_blank">Knowledge base</a>
        <div className="dropdown-divider"></div>
        <button className="list-group-item" onClick={() => executeLogoutMutation()} disabled={logoutResult.fetching}>
          <FontAwesomeIcon icon={icons.signOut}/>
          Logout
        </button>
      </div>
      }
    </div>
  );
}
