import { faListMusic } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { useFloatingSetlistSongQuery } from '../generated/graphql';
import { Loading } from '../chrome/Loading';


interface Props {
  id: number
  linkTo: string
}


export const FloatingSetlistSong = (props: Props) => {

  const [result] = useFloatingSetlistSongQuery({
    variables: {id: props.id},
    requestPolicy: "cache-first",
  });

  const setlistSong = result.data?.setlistSong

  // Calc song number
  let songNo: number | null = null
  if (setlistSong) {
    const allSongs = setlistSong?.setlist.songs
    for (let i=0; i<allSongs.length; i++) {
      if (allSongs[i].id === props.id) {
        songNo = i + 1
      }
    }
  }
  // const songNo = setlistSong?.setlist.songs.filter(s => s.id === props.id)[0]

  if (setlistSong) {
    return (
      <Link to={props.linkTo} className="btn btn-dark rounded-0 text-left d-flex align-items-center pl-3 py-1 text-white">
        <FontAwesomeIcon icon={faListMusic} size="2x"/>
        <div className="d-flex flex-column ml-3">
          <div>
            {/* {songNo && `${songNo}. `} */}
            {setlistSong.title}
            {songNo && ` (${songNo} of ${setlistSong?.setlist.songs.length || 1})`}
          </div>
          <div className="small">
            {/* {songNo && `Song ${songNo} of ${setlistSong?.setlist.songs.length || 1} in `} */}
            <b>{setlistSong?.setlist.title}</b>
          </div>
        </div>
      </Link>
    )
  }
  
  return <Loading/>
};