import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import ReactSidebar from 'react-sidebar';
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params';
import { AppDrawer } from '../app/AppDrawer';
import { Loading } from '../chrome/Loading';
import { LibraryFragment, LibraryType, MeFragment, useDeleteSongMutation, useLibraryQuery } from '../generated/graphql';
import { icons } from '../icons';
import { buildLinkForLibrary, isRoute, routes, useRouteParams } from '../routes';
import { SetlistView } from '../setlist/SetlistView';
import { settings } from '../settings';
import { SongView } from '../song/SongView';
import { FloatingItem } from './FloatingItem';
import { FloatingSetlist } from './FloatingSetlist';
import { FloatingSetlistSong } from './FloatingSetlistSong';
import { FloatingSong } from './FloatingSong';
import { LibraryContext } from './LibraryContext';
import { LibraryModal } from './LibraryModal';
import { LibraryTab } from './LibraryTab';
import { LibraryTabs } from './LibraryTabs';
import { LibraryTabSetlists } from './LibraryTabSetlists';
import { LibraryTabSettings } from './LibraryTabSettings';
import { LibraryTabSongs } from './LibraryTabSongs';
import { NavBarTwoLineBtn } from './NavBarTwoLineBtn';
import { Toolbar } from './Toolbar';


interface Props {
  id: number
  library: LibraryFragment | null
  onChange?: () => void
  me?: MeFragment
}


export const LibraryView = (props: Props) => {
  const buildLibraryLink = buildLinkForLibrary(props.id)
  const p = useRouteParams()
  const history = useHistory();
  const library = props.library;

  // const [createSetlistResult, createSetlist] = useCreateSetlistMutation();
  const [, deleteSong] = useDeleteSongMutation();

  // const media = useMedia({queries: GRID_MEDIA_QUERIES})
  
  const [queryParams, setQueryParams] = useQueryParams({
    search: BooleanParam,
    sidebar: BooleanParam,
    q: StringParam,
  })

  // Current Modal
  type CurrentSetlistSong = ['setlistsong', number, string]
  type CurrentSetlist = ['setlist', number, string]
  type CurrentSong = ['song', number, string]
  type CurrentModal = CurrentSetlist | CurrentSong | CurrentSetlistSong
  const [currentModal, setCurrentModal] = React.useState<CurrentModal | null>(null)

  function updateCurrentModal(location: {pathname: string, search: string, hash: string}) {

    const song = isRoute.song(location.pathname)
    const setlist = isRoute.setlist(location.pathname)
    const setlistSong = isRoute.setlistsong(location.pathname)
    if (song) {
      // console.log(['song', song, location.pathname + location.search + location.hash])
      setCurrentModal(['song', song, location.pathname + location.search + location.hash])
    }
    else if (setlist) {
      // console.log(['setlist', setlist, location.pathname + location.search + location.hash])
      setCurrentModal(['setlist', setlist, location.pathname + location.search + location.hash])
    }
    else if (setlistSong) {
      console.log(['setlistSong', setlistSong, location.pathname + location.search + location.hash])
      setCurrentModal(['setlistsong', setlistSong, location.pathname + location.search + location.hash])
    }
  }

  React.useEffect(() => {
    history.listen((location, action) => {
      updateCurrentModal(location)
    })
    updateCurrentModal(window.location)
  }, [history])

  let modal: React.ReactNode | null = null


  // Routes
  const setlistRouteMatch = useRouteMatch<{setlist: string}>(routes.setlist);

  const routeMatches = {
    setlists: useRouteMatch({path: routes.setlists, exact: true}),
    songs: useRouteMatch({path: routes.songs, exact: true}),
    library: useRouteMatch({path: routes.library, exact: true}),
  }
  // const setlistsRouteMatch = useRouteMatch({path: routes.setlists, exact: true});
  // const songsRouteMatch = useRouteMatch({path: routes.songs, exact: true});
  // const setlistSongRouteMatch = useRouteMatch<{setlist: string}>(routes.setlistSong);

  const songRouteMatch = useRouteMatch<{song: string}>(routes.song);
  const editSongRouteMatch = useRouteMatch<{song: string}>(routes.editSong);

  if (setlistRouteMatch) {
    modal = (
      <div className="d-flex h-100 overflow-auto flex-column">
        <SetlistView 
          id={parseInt((setlistRouteMatch)?.params.setlist || '0')} 
          className="w-25 overflow-auto" 
          // onDelete={() => reexecuteQuery()}
          backTo={buildLibraryLink.setlists()}
        />
      </div>
    )
  }

  else if (songRouteMatch || editSongRouteMatch) {
    modal = (
      <div className="d-flex h-100  ">
        <SongView
          id={parseInt((songRouteMatch || editSongRouteMatch)?.params.song || '0')} 
        />
      </div>
    )
  }
  console.log(routeMatches)

  // else if (result.data?.library) {
  //   modal = (
  //     <LibraryDashboard library={result.data?.library} onDeleteSong={handleDeleteSong} onChange={() => {reexecuteQuery()}}/>    
  //   )
  // }
  // else {
  //   modal = <Loading/>
  // }

  // const dropdownItems = <>
  //   <a className="dropdown-item" href="#">Edit library</a>
  //   <a className="dropdown-item" href="#">Users &amp; access</a>
  //   <a className="dropdown-item" href="#">Song usage report</a>
  //   <div className="dropdown-divider"/>
  //   <a className="dropdown-item" href="#">Import data</a>
  //   <a className="dropdown-item" href={settings.serverURL + `/songs/${props.library.id}/export/all/zip-of-pdfs/`} target="_blank">Export zip archive of all songs PDF's</a>
  //   <div className="dropdown-divider"/>
  //   <a className="dropdown-item text-danger" href="#">Delete library</a>
  // </>

  const tabs = [
    {
      key: 'songs',
      text: 'Songs', 
      icon: icons.songs, 
      to: buildLibraryLink.songs(), 
      active: p.view === 'songs' || (!!p.song && !p.setlist),
      ref: React.useRef<HTMLDivElement>(null),
      children: 
        <LibraryTabSongs
          autoFocus={queryParams.search || false}
          onSearchChange={q => {setQueryParams({q})}}
          q={queryParams.q || ''} 
          pause={routeMatches.songs === null}
        />,
        toolbar: {
          more: <>
            <button className="dropdown-item">Add new song</button>
            <button className="dropdown-item">Import songs from other systems</button>
            <div className="dropdown-divider"/>
            <button className="dropdown-item">Export zip archive of all songs</button>
          </>,
          right: (
            <li className="nav-item">
              <Link className="nav-link" to="#">
                <FontAwesomeIcon icon={icons.plus}/>
              </Link>
            </li>
          )
        },
    },
    {
      key: 'setlists',
      text: 'Setlists', 
      icon: icons.setlist, 
      to: buildLibraryLink.setlists(), 
      active: p.view === 'setlists' || !!p.setlist,
      children: <LibraryTabSetlists pause={routeMatches.setlists === null} />,
      ref: React.useRef<HTMLDivElement>(null),
      toolbar: {
        right: (
          <li className="nav-item">
            <Link className="nav-link" to="#">
              <FontAwesomeIcon icon={icons.plus}/>
            </Link>
          </li>
        )
      },
    },
    {
      key: 'livesync',
      text: 'LiveSync', 
      icon: icons.liveSync, 
      to: buildLibraryLink.setlists(), 
      active: p.view === 'settings',
      children: <div>Live sync</div>,
      ref: React.useRef<HTMLDivElement>(null),
    },
    {
      key: 'presenter',
      text: 'Presenter', 
      icon: icons.presenter, 
      to: buildLibraryLink.setlists(), 
      active: p.view === 'settings',
      children: <div>Live sync</div>,
      ref: React.useRef<HTMLDivElement>(null),
    },
    {
      key: 'settings',
      text: 'Settings', 
      icon: icons.settings, 
      to: buildLibraryLink.library(), 
      active: p.view === 'library',
      children: library ?
        <LibraryTabSettings
          pause={routeMatches.library === null}
          id={props.id}
        />
      :
        <Loading/>,
      ref: React.useRef<HTMLDivElement>(null),
      toolbar: {more: <>
        <button className="dropdown-item">Edit library</button>
        <button className="dropdown-item">Users &amp; access</button>
        <button className="dropdown-item">Song usage report</button>
        <div className="dropdown-divider"/>
        <button className="dropdown-item">Import data</button>
        <a className="dropdown-item" href={settings.serverURL + `/songs/${props.id}/export/all/zip-of-pdfs/`} rel="noreferrer" target="_blank">Export zip archive of all songs PDF's</a>
        <div className="dropdown-divider"/>
        <button className="dropdown-item text-danger">Delete library</button>
      </>}
    },
  ]

  const selectedTab = tabs.filter(t => t.active)[0] || null

  const handleCurrentClose = () => {
    setCurrentModal(null)
  }

  const [appDrawer, setAppDrawer] = React.useState(false)

  return (
    <LibraryContext.Provider value={{id: props.id, buildLink: buildLinkForLibrary(props.id), readOnly: library?.myAccess !== 'FULL_CONTROL'}}>
      <ReactSidebar
        sidebar={<AppDrawer me={props.me}/>}
        open={appDrawer}
        onSetOpen={setAppDrawer}
        styles={{ sidebar: { background: "white", zIndex: '3002', }, overlay: {zIndex: '3001'} }}
      >
        <>
        <div className="h-100" style={{paddingTop: '62px'}}>
          <div className="tab-content h-100" id="myTabContent" style={{paddingBottom: '44px'}}>
            {tabs.map(tab => (
              <LibraryTab key={tab.key} ref={tab.ref} name={tab.key} active={tab.active}>
                {tab.children}
              </LibraryTab> 
            ))}
          </div>
          <div className="fixed-bottom">
            {!modal && currentModal && currentModal[0] === 'song' &&
              <FloatingItem onClose={handleCurrentClose}>
                <FloatingSong id={currentModal[1]} linkTo={currentModal[2]}/>
              </FloatingItem>
            }
            {!modal && currentModal && currentModal[0] === 'setlist' &&
              <FloatingItem onClose={handleCurrentClose}>
                <FloatingSetlist id={currentModal[1]} linkTo={currentModal[2]}/>
              </FloatingItem>
            }
            {!modal && currentModal && currentModal[0] === 'setlistsong' &&
              <FloatingItem onClose={handleCurrentClose}>
                <FloatingSetlistSong id={currentModal[1]} linkTo={currentModal[2]}/>
              </FloatingItem>
            }
            <LibraryTabs tabs={tabs} />
          </div>
          {/* <div className="pt-4 pb-5"></div> */}
        </div>
        <Toolbar 
          burger
          fixed="top"
          clear
          // back={{to: '/', text: 'Home'}}
          // more={dropdownItems}
          main={
            <NavBarTwoLineBtn 
              line1={library?.title || 'Library Loading...'} 
              line2={library?.description || (library?.type === LibraryType.Personal ? 'You personal library' : `Shared with ${library?.memberCount} people`)}
            />
          }
          onBurger={() => setAppDrawer(true)}
          className="navbar navbar-expand navbar-light px-0 border-bottom"
          {...selectedTab?.toolbar}
        />
        <LibraryModal>
          {modal}
        </LibraryModal>
        </>
      </ReactSidebar>
    </LibraryContext.Provider>
  );
  
};