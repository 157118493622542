import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cs from 'classnames';
import React from "react";
import { Link } from "react-router-dom";
import { TransposeStyle } from "../generated/graphql";
import { icons } from "../icons";
import { KeySelectProps } from "./KeySelect";
import { useKeySelect } from "./KeySelectHooks";
import { transposeNote } from "./transpose";


export const KeySelectUpDown = (props: KeySelectProps) => {

  const key = useKeySelect(props)

  return (
    <div className="btn-group">
        <button
          title="Transpose down one semitone"
          onClick={key.handleTransposeDown}
          className={cs(props.btnClass || "btn btn-outline-secondary")}
        >
          <FontAwesomeIcon icon={icons.keyDown}/>
        </button>
        <button
          data-toggle="dropdown"
          title="Select Key"
          style={{ minWidth: "50px"  }}
          className={cs(props.btnClass || "btn btn-outline-secondary")}
        >
          {key.keyDisplay}
        </button>
        <button
          title="Transpose up one semitone"
          onClick={key.handleTransposeUp}
          className={cs(props.btnClass || "btn btn-outline-secondary")}
        >
          <FontAwesomeIcon icon={icons.keyUp}/>
        </button>

      <div className={cs("dropdown-menu", props.align && 'dropdown-menu-right', 'dropdown-menu-iconed')}>
        {key.choices().map(k => (
          <Link
            key={k.value}
            className={cs('d-flex', 'dropdown-item', props.semitones === k.value ? "active" : "", k.value === 0 && 'font-weight-bold')}
            to={`?transpose=${k.value}&style=${props.style}`}
            onClick={e => key.handleTranspose(e, k.value)}
          >
            {key.currentKey ? (
              <>
                <span className="mr-auto">
                  {transposeNote(
                    props.originalKey,
                    k.value,
                    props.style === TransposeStyle.Flats ? 'b' : '#'
                  )}{" "}
                </span>
                <small className={cs(props.semitones === k.value ? "" : "text-muted",  k.value === 0 && 'font-weight-bold')}>{k.display}</small>
              </>
            ) : (
              k.display
            )}
          </Link>
        ))}

        <>
          <div className="dropdown-divider" />
          <Link
            to={`?transpose=${props.semitones}#`}
            onClick={key.handleStyleSharps}
            className={cs('dropdown-item', props.style === TransposeStyle.Sharps ? "active" : "")}
          >
            <span className="dropdown-item-texticon"><b>♯</b></span>
            Show with sharps
          </Link>
          <Link
            to={`?transpose=${props.semitones}b`}
            onClick={key.handleStyleFlats}
            className={cs('dropdown-item', props.style === TransposeStyle.Flats ? "active" : "")}
          >
            <span className="dropdown-item-texticon"><b>&#9837;</b></span>
            Show with flats 
          </Link>
          {key.currentKey && props.semitones !== 0 && props.onSave && (
            <>
              <div className="dropdown-divider" />
              <a className="dropdown-item" href="." onClick={e => {e.preventDefault(); if (props.onSave) props.onSave()}}>
                <FontAwesomeIcon icon={icons.saveKey}/>Save song in this key
              </a>
            </>
          )}
        </>
        
      </div>
    </div>
  );
}
