import { DisplayKey } from "./KeySelect";

const NOTE_INDEXES: { [note: string]: number } = {
  "B#": 0,
  C: 0,
  "C#": 1,
  Db: 1,
  D: 2,
  "D#": 3,
  Eb: 3,
  E: 4,
  "E#": 5,
  Fb: 5,
  F: 5,
  "F#": 6,
  Gb: 6,
  G: 7,
  "G#": 8,
  Ab: 8,
  A: 9,
  "A#": 10,
  Bb: 10,
  B: 11,
  Cb: 11
};
const SHARP_NOTES = [
  "C",
  "C#",
  "D",
  "D#",
  "E",
  "F",
  "F#",
  "G",
  "G#",
  "A",
  "A#",
  "B"
];
const FLAT_NOTES = [
  "C",
  "Db",
  "D",
  "Eb",
  "E",
  "F",
  "Gb",
  "G",
  "Ab",
  "A",
  "Bb",
  "B"
];

export const cleanSemitones = (value: number) => {
  while (value < 0) value += 12;
  while (value >= 12) value -= 12;
  return value;
};

export const transposeNote = (
  note: string,
  semitones: number,
  style: "#" | "b" = "#"
) => {
  semitones = cleanSemitones(semitones);
  const i = NOTE_INDEXES[note] + semitones;
  if (style === "#") return SHARP_NOTES[i % 12];
  if (style === "b") return FLAT_NOTES[i % 12];
  return null;
};

export interface Chord {
  root: string;
  voicing: string; // Eg '' (for Major), m, sus4, etc.
  bass: string;
}

export const chordSplit = (chordStr: string): Chord | null => {
  const m = /^(([a-gA-G][#bB]{0,1})([\wº+△]*)){0,1}(\/([a-gA-G][#bB]{0,1})){0,1}$/gi.exec(
    chordStr
  );

  if (m)
    return {
      root: m[2] || "",
      voicing: m[3] || "",
      bass: m[5] || ""
    };
  return null;
};

export const chordJoin = (chord: Chord) =>
  `${chord.root}${chord.voicing}${chord.bass ? "/" + chord.bass : ""}`;

export const transposeChord = (
  fullChord: string,
  semitones: number,
  style: "#" | "b" = "#"
) => {
  const bits = chordSplit(fullChord);
  if (!bits) return null;

  return chordJoin({
    root: transposeNote(bits.root, semitones, style) || "",
    bass: transposeNote(bits.bass, semitones, style) || "",
    voicing: bits.voicing
  });
};

export const isChord = (val: string) =>
  transposeChord(val.trim(), 0) === null ? false : true;

export const isChordLine = (line: string) => {
  let numChords = 0;
  for (let w of line
    .trim()
    .replace(/|/g, " ")
    .split(" ")) {
    if (w.length > 0) {
      if (isChord(w)) numChords += 1;
      else return false;
    }
  }
  return numChords > 0;
};

export const transposeLine = (
  line: string,
  semitones: number,
  style: "#" | "b" = "#"
) => {
  //   Split into chords & white space
  const chords: string[] = [];
  let chord = "";
  for (let i = 0; i < line.length; i++) {
    const l = line[i];

    if (chord.length === 0) {
      chord += l;
    } else if (chord.trim() === "") {
      if (l === " ") {
        chord += l;
      } else {
        chords.push(chord);
        chord = l;
      }
    } else {
      if (l === " ") {
        chords.push(chord);
        chord = l;
      } else {
        chord += l;
      }
    }
  }
  if (chord.length > 0) chords.push(chord);

  // Transpose the chords
  const transposed: string[] = [];
  for (let chord of chords) {
    // eslint-disable-next-line
    if (chord.trim() !== "" && !chord.trim().match(/^[/|\-\(\)\[\]x\d]+$/g)) {
      let newChord = transposeChord(chord, semitones, style);
      if (newChord === null) newChord = "?".repeat(chord.length);
      transposed.push(newChord);
    } else transposed.push(chord);
  }
  // Fix up the white space
  for (let i = 0; i < chords.length; i++) {
    if (transposed[i].trim() === "") {
      const l =
        chords.slice(0, i + 1).join("").length -
        transposed.slice(0, i).join("").length;
      transposed[i] = " ".repeat(Math.max(l, 0));
    }
  }

  // And update with the transposed chords
  return transposed.join("");
};

export const transposePlainChordchart = (
  chord_chart: string,
  semitones: number,
  style: "#" | "b" = "#"
) => {
  const lines: string[] = [];
  if (semitones !== 0) {
    for (let line of chord_chart.split("\n")) {
      // eslint-disable-next-line
      const m = line.match(/'^([>\.])(.*)$'/g);
      if (m)
        lines.push(m[1] + transposeLine(m[2].trimRight(), semitones, style));
      else lines.push(line);
    }
  }
  return lines.join("\n");
};

export const parseTransposeByStr = (transposeby: string) => {
  // eslint-disable-next-line
  const m = transposeby.match(/^(\-{0,1}\d)([#b]{0,1})$/g);
  return m ? { semitones: parseInt(m[1]), style: m[2] || "#" } : null;
};

export const getTransposeChoices = (key: string) => {
  let NOTES: string[] | undefined = undefined;
  let root = "";
  let suffix = "";
  if (SHARP_NOTES.indexOf(key.substr(0, 2)) !== -1) {
    NOTES = SHARP_NOTES;
    root = key.substr(0, 2);
    suffix = key.substr(2);
  } else if (FLAT_NOTES.indexOf(key.substr(0, 2)) !== -1) {
    NOTES = FLAT_NOTES;
    root = key.substr(0, 2);
    suffix = key.substr(2);
  } else if (SHARP_NOTES.indexOf(key.substr(0, 1)) !== -1) {
    NOTES = SHARP_NOTES;
    root = key.substr(0, 1);
    suffix = key.substr(1);
  } else if (FLAT_NOTES.indexOf(key.substr(0, 1)) !== -1) {
    NOTES = FLAT_NOTES;
    root = key.substr(0, 1);
    suffix = key.substr(0, 1);
  } else {
    return null;
  }
  const x = NOTES.indexOf(root);
  const choices: { value: number; display: string }[] = [];
  for (let i = 0; i < NOTES.length; i++) {
    let t = i - x;
    if (t < -5) t += 12;
    if (t > 6) t -= 12;
    if (t > 0)
      choices.push({ value: t, display: `${NOTES[i]}${suffix} (+${t})` });
    else if (t < 0)
      choices.push({ value: t, display: `${NOTES[i]}${suffix} (${t})` });
    else choices.push({ value: t, display: `${NOTES[i]}${suffix}` });
  }
  return choices;
};

// For putting on the end of urls for songlib.com
export const getSonglibTransposeQuery = (transpose?: DisplayKey) => 
  (transpose?.semitones ? `?transpose=${transpose.semitones}${transpose.style === 'FLATS' ? 'b': ''}`: '')