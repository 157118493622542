import cs from 'classnames';
import React from 'react';
import { FieldConfig, useField } from './hooks';
import styles from './TextField.module.scss';


export interface Props<T extends string> extends FieldConfig<T> {
  label?: string
  pattern?: string
  type?: string
  size?: 'sm' | 'lg'
}


export const TextField = <T extends string>(props: Props<T>) => {
  const f = useField(props)
  return (
    <div className={cs("form-group", styles.FormField)}>
      <input 
        type={props.type || 'text'}
        placeholder={props.label}
        className={cs(
          'form-control', 
          f.validationStatus,
          props.size && `form-control-${props.size}`
        )}
        value={f.value}
        onChange={f.handleChange}
        onBlur={f.handleBlur}
      />
      <label>{props.label}</label>
      {f.error !== null && <div className="text-danger small mt-1">{f.error}</div>}
    </div>
  )
}