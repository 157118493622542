import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { required, TextField } from '../fields';
import { LibraryAccess, useLibrarySettingsQuery } from '../generated/graphql';
import { icons } from '../icons';
import { Loading } from '../chrome/Loading';


interface Props {
  pause?: boolean
  id: number
}


export const LibraryTabSettings = (props: Props) =>  {


  const [result] = useLibrarySettingsQuery({
    variables: {
      id: props.id,
    },
    requestPolicy: "cache-and-network",
  });

  const library = result.data?.library
  const members = result.data?.library?.members

  return (
    <>
      {!library && <Loading/>}
      {library && 
        <div className="py-3 container-fluid">

          <h4 className="p-3 text-center">Library details</h4>

          <TextField name="title" label="Title" validators={[required]} initialValue={library.title} size="lg" onCommit={undefined} />
          <TextField name="description" label="Description" validators={[]} initialValue={library.description} onCommit={undefined} />
          <TextField name="ccli" label="CCLI number" validators={[]} initialValue={(library.ccli || '').toString()} onCommit={undefined} />
          <button className="btn btn-light">Select new Image</button>
        </div>
      }

      <h4 className="p-3 text-center">Members</h4>

      {!members && <Loading/>}
      {members && 
        <table className="table">
          <tbody>

            {members && members.map(m => (
              <tr key={m.id}>
                <td>
                  {m.user.fullName}
                  <div className="text-muted small">{m.user.email}</div>
                </td>
                {/* <td>
                  {m.access === LibraryAccess.FullControl && 'Full-control'}
                  {m.access === LibraryAccess.ReadOnly && 'Read-only'}
                  {m.access === LibraryAccess.NoAccess && 'No access'}
                </td> */}
                <td className="text-right">
                  <div className="dropdown">
                    <button className="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {m.access === LibraryAccess.FullControl && 'Full-control'}
                      {m.access === LibraryAccess.ReadOnly && 'Read-only'}
                      {m.access === LibraryAccess.NoAccess && 'No access'}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right dropdown-menu-iconed" aria-labelledby="dropdownMenuButton">
                      <button className="dropdown-item">
                        <FontAwesomeIcon icon={m.access === LibraryAccess.ReadOnly ? icons.radioChecked : icons.radioUnchecked}/> Read-only
                      </button>
                      <button className="dropdown-item">
                      <FontAwesomeIcon icon={m.access === LibraryAccess.FullControl ? icons.radioChecked : icons.radioUnchecked}/> Full-control
                      </button>
                      <button className="dropdown-item">
                      <FontAwesomeIcon icon={m.access === LibraryAccess.NoAccess ? icons.radioChecked : icons.radioUnchecked}/> No-access
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>



      }
      <div className="py-3 container-fluid text-center">
        <button className="btn btn-success">Add new member</button>
      </div>

      <div className="py-3 container-fluid text-center">
        <h4 className="p-3 text-center">Dangerous Actions</h4>
        <button className="btn btn-danger">Delete entire library</button>
      </div>
    </>
  )
}
