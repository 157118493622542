import { faListMusic } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { useFloatingSetlistQuery } from '../generated/graphql';
import { Loading } from '../chrome/Loading';


interface Props {
  id: number
  linkTo: string
}


export const FloatingSetlist = (props: Props) => {

  const [result] = useFloatingSetlistQuery({
    variables: {id: props.id},
    requestPolicy: "cache-first",
  });

  const setlist = result.data?.setlist

  if (setlist) {
    return (
      <Link to={props.linkTo} className="btn btn-dark rounded-0 text-left d-flex align-items-center pl-3 py-1 text-white">
        <FontAwesomeIcon icon={faListMusic} size="2x"/>
        <div className="d-flex flex-column ml-3">
          <div>{setlist.title}</div>
          <div className="small">
            Setlist 
            {setlist.leader?.shortName && ` lead by ${setlist.leader.shortName}`} 
            {setlist.date && ` on ${setlist.date}`}
          </div>
        </div>
      </Link>
    )
  }
  
  return <Loading/>
};