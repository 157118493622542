import React from 'react';
import { SongsByLetter } from '../songs/SongsByLetter';
import { SongsContainer } from '../songs/SongsContainer';


interface Props {
  q: string
  onSearchChange: (q: string) => void
  autoFocus: boolean
  pause: boolean
}


export const LibraryTabSongs = (props: Props) =>  (
  <div>
    {/* <Toolbar burger main={
      <NavBarTwoLineBtn 
        line1={result.data?.library?.title || 'Library Loading...'} 
        line2="Songs"
      />}
      onBurger={() => meContext.setSidebarOpen(true)}
    /> */}
    {/* <h3 className="p-3 mt-navbar">All Songs</h3> */}
    {/* <div className="pt-3 pb-5"></div> */}
    <div>
      <div className="p-3 mb-3">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <input 
              defaultValue={props.q} 
              onChange={e => {props.onSearchChange(e.target.value || '')}}
              className="form-control form-search-input rounded-pill pl-5"
              placeholder="Start typing to search..."
              autoFocus={props.autoFocus}
            />
          </div>
        </div>
      </div>
    </div>
    <SongsContainer pause={props.pause} className="px-3">{p => 
      <SongsByLetter {...p}/>
    }</SongsContainer>
  </div>
)