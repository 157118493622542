import { faAngleRight, faBars } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { MainContentPane } from '../chrome/MainContentPane';
import { SetlistSongFragment, TransposeStyle } from '../generated/graphql';
import { icons } from '../icons';
import { LibraryContext } from '../library/LibraryContext';
import { ListInfo } from '../utils/listInfo';
import { ChordChartAutoSizing } from '../song/ChordChartAutoSizing';
import { DisplayKey, KeySelect } from '../transpose/KeySelect';




interface Props {
  setlistId: number
  listInfo: ListInfo<SetlistSongFragment>
  setlistSong: SetlistSongFragment
  onKeyChange: (value: DisplayKey) => void
  onSidebarToggle: () => void
  sizeSource?: any
  sidebarToggle: boolean
  back: boolean
}

export const SetlistSongPane = ({setlistSong, listInfo, ...props}: Props) => {
  const lib = React.useContext(LibraryContext);

  const thisIndex = (() => {
    for (let i=0; i<listInfo.items.length; i++) {
      if (listInfo.items[i].id === setlistSong.id) {
        return i;
      }
    }
  })();

  return (
    <MainContentPane
      scrollContent
      back={
        <>
          {props.sidebarToggle && 
            <button className="btn btn-plain rounded-0 d-lg-none" title="Back to setlists" onClick={props.onSidebarToggle}>
              <FontAwesomeIcon icon={faBars}/>
              <FontAwesomeIcon icon={faAngleRight} className="ml-1"/>
            </button>
          }
          {props.back && 
            <Link to={lib.buildLink.setlist(props.setlistId)} className="btn btn-plain rounded-0 d-lg-none" title="Back">
              <FontAwesomeIcon icon={icons.back}/>
            </Link>
          }
        </>
      }
      title={
        setlistSong.song &&
          <h1 className="my-0 d-flex flex-wrap align-items-end">
            <span className="mr-2">{(thisIndex || 0) + 1}. {setlistSong.title}</span>
            {/* {song.author && <small className="lead text-muted">{song.author}</small>} */}
          </h1>
      }
      actions={
        setlistSong.song && 
          <>
            <KeySelect
              originalKey={setlistSong.transpose.fromKey || ''}
              semitones={setlistSong.transpose.semitones || 0}
              style={setlistSong.transpose.style || TransposeStyle.Sharps}
              align="right"
              btnClass="btn-plain btn"
              onChange={props.onKeyChange}
            />
            <div className="btn-group ml-4">
              <Link 
                className={cs("btn btn-plain", (listInfo.prevIndex === null) && 'disabled')}
                to={listInfo.prevItem ? lib.buildLink.setlistsong(props.setlistId, listInfo.prevItem.id) : '.'}
                title="Previous song in setlist"
              >
                <FontAwesomeIcon icon={icons.prevSong}/>
              </Link>
              <span className="btn btn-plain px-0">
                {(thisIndex || 0) + 1}&nbsp;of&nbsp;{listInfo.count}
              </span>
              <Link 
                className={cs("btn btn-plain", (listInfo.nextIndex === null) && 'disabled')}
                to={listInfo.nextItem ? lib.buildLink.setlistsong(props.setlistId, listInfo.nextItem.id) : '.'}
                title="Next song in setlist"
              >
                <FontAwesomeIcon icon={icons.nextSong}/>
              </Link>
            </div>
          </>
      }
    >
      {setlistSong &&  
        <>  
          {listInfo.selectedItem?.notes && <p className="font-italic text-info">{listInfo.selectedItem?.notes}</p>}  
          <ChordChartAutoSizing
            plainText={setlistSong.song.chordChart || ''}
            transpose={{
              semitones: setlistSong.transpose.semitones || 0,
              style:  setlistSong.transpose.style || TransposeStyle.Sharps,
            }}
            sizeSource={props.sizeSource}
          />
        </>
      }
    </MainContentPane>
  )
}