import cs from 'classnames';
import { Field, useField } from 'formik';
import React from 'react';
import formStyles from '../fields/TextField.module.scss';


interface Props {
  label: string
  name: string
  as?: 'textarea' | 'select'
  rows?: number
  children?: React.ReactNode
}

export const InputFormGroup = (props: Props) => {
  const [field, meta] = useField(props)
  const {...passThruProps} = props; 
  return (
    <div className={cs("form-group", formStyles.FormField)}>
      <Field 
        placeholder={props.label}
        className={cs('form-control')}
        // {...field}
        {...passThruProps}
        // {...formik.getFieldProps('date')}
      />
      <label>{props.label}</label>
      {meta.touched && meta.error !== null && 
        <div className="text-danger small mt-1">{meta.error}</div>
      }
    </div>
  )
}
