import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cs from 'classnames';
import React from "react";
import { Link } from "react-router-dom";
import { TransposeStyle } from "../generated/graphql";
import { icons } from "../icons";
import { useKeySelect } from "./KeySelectHooks";
import { transposeNote } from "./transpose";


interface Props extends ReturnType<typeof useKeySelect> {
  // currentKey: DisplayKey;
  // originalKey: string;
  className?: string;
  // choices: KeyChoice[];
  // onTranspose: TransposeEvent;
  // onSave?: () => void;
}


export const KeySelectDropdownMenu = (props: Props) => (
  <div className={cs("dropdown-menu", 'dropdown-menu-iconed', props.className)}>
    {props.choices().map(k => (
      <Link
        key={k.value}
        className={cs('d-flex', 'dropdown-item', props.semitones === k.value ? "active" : "", k.value === 0 && 'font-weight-bold')}
        to={`?transpose=${k.value}&style=${props.style}`}
        onClick={e => props.handleTranspose(e, k.value)}
      >
        {props.currentKey ? (
          <>
            <span className="mr-auto">
              {transposeNote(
                props.originalKey,
                k.value,
                props.style === TransposeStyle.Flats ? 'b' : '#'
              )}{" "}
            </span>
            <small className={cs(props.semitones === k.value ? "" : "text-muted",  k.value === 0 && 'font-weight-bold')}>{k.display}</small>
          </>
        ) : (
          k.display
        )}
      </Link>
    ))}

    <>
      <div className="dropdown-divider" />
      <Link
        to={`?transpose=${props.semitones}#`}
        onClick={props.handleStyleSharps}
        className={cs('dropdown-item', props.style === TransposeStyle.Sharps ? "active" : "")}
      >
        <span className="dropdown-item-texticon"><b>♯</b></span>
        Show with sharps
      </Link>
      <Link
        to={`?transpose=${props.semitones}b`}
        onClick={props.handleStyleFlats}
        className={cs('dropdown-item', props.style === TransposeStyle.Flats ? "active" : "")}
      >
        <span className="dropdown-item-texticon"><b>&#9837;</b></span>
        Show with flats 
      </Link>
      {props.currentKey && props.semitones !== 0 && props.onSave && (
        <>
          <div className="dropdown-divider" />
          <a className="dropdown-item" href="." onClick={e => {e.preventDefault(); if (props.onSave) props.onSave()}}>
            <FontAwesomeIcon icon={icons.saveKey}/>Save song in this key
          </a>
        </>
      )}
    </>
  </div>
);
